import React from "react";
import {
  Card, Col, Row,
} from "reactstrap";
import ClockWhite from "../../../../img/clock(white).svg"
import LetterWhite from "../../../../img/letter(white).svg"

export default function CardRecommend({ obj, onClickToPage, calculateCredit }) {
  return (
    <div>
      <div className="recommend-top-card">
        <h3 style={{ color: "white" }}>
          Recommend
        </h3>
      </div>
      <Card className="card-package-center-recommend">
        <h3 className="card-title-recommend">{obj.PackageName}</h3>
        <div style={{ textAlign: "center", margin: "15px" }}>
          <div style={{ fontSize: "25px" }}>
            <span className="plan-title" style={{ fontSize: "3rem" }}>
              {obj.PackagePrice_per_credit}
            </span>
            <span style={{ color: '#ff5e00', fontSize: '35px' }}> THB/SMS</span>
          </div>
          <hr className="hr-card-recommend" />
          <div style={{ fontSize: "30px" }}>
            <div style={{ fontSize: "40px" }}>
              Price
            </div>
            <span className="plan-sub-title-recommned">
              {(obj.Packagetotal_price)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
            ฿
          </div>
        </div>
        <div className="card-content">
          <Row>
            <Col xl="6" style={{ borderRight: '1px solid #ffffff' }}>
              <div className="text-desc-recommend">
                <img className="icon-card" src={ClockWhite} />
                <span style={{ fontSize: "20px" }}>
                  Credit period
                </span>
              </div>
              <div className="text-desc-recommend">
                <img className="icon-card" src={ClockWhite} />
                <span style={{ fontSize: "20px" }}>
                  Report period
                </span>
              </div>
              <div className="text-desc-recommend">
                <img className="icon-card" src={LetterWhite} />
                <span style={{ fontSize: "20px" }}>
                  SMS Qty
                </span>
              </div>
              <div className="text-desc-recommend">
                <img className="icon-card" src={LetterWhite} />
                <span style={{ fontSize: "20px" }}>
                  Sender name
                </span>
              </div>
            </Col>
            <Col xl="6">
              <div className="text-desc-recommend">
                <span style={{ fontSize: "20px" }}>
                  {obj.Package_Packageexpire} Month
                </span>
              </div>
              <div className="text-desc-recommend">
                <span style={{ fontSize: "20px" }}>
                  {obj.Package_Reportexpire} Month
                </span>
              </div>
              <div className="text-desc-recommend">
                <span style={{ fontSize: "20px" }}>
                  {obj.Packagetotal_message.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </div>
              <div className="text-desc-recommend">
                <span style={{ fontSize: "20px" }}>
                  {obj.Package_Sender === 0 ? "Unlimited" : obj.Package_Sender}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ margin: "0px 30px 20px" }}>
          <Card
            className={"button-white"}
            onClick={() => {
              let pid =
                obj.PackageId + "|" + obj.Packagetotal_price;
              onClickToPage(pid, obj.PackageType);
            }}
          >
            <h2 style={{ color: "#ff5e00", margin: "0px" }}>Buy now</h2>
          </Card>
        </div>
      </Card>
    </div>
  );
}
