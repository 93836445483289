import React, { Component } from "react";
import {
  PageHeader,
  Button,
  Descriptions,
  Select,
  Row,
  Col,
  DatePicker,
  Icon,
  Form,
  Checkbox,
  Input,
  Card,
  CardBody,
  Tooltip,
  Progress,
  Table,
  Divider,
  Tag,
  Modal,
  Upload,
  Drawer,
  Spin,
  Collapse,
  Breadcrumb,
  Radio,
  Space,
  notification,
  Typography,
} from "antd";
import {
  CheckUserLogedin,
  CheckTokenExp,
  getUrlVars,
  formatCurrency,
  token,
  packagelistPage
} from "../../../config";
import queryString from "query-string";
import OmiseCreditCard from "../../../components/Omise/OmiseCreditCard";
import OmiseInternetBanking from "../../../components/Omise/OmiseInternetBanking";
import OmiseTrueMoney from "../../../components/Omise/OmiseTrueMoney";

import provinces from "../../../Autoprovince/Autoprovince/provinces.json";
import districts from "../../../Autoprovince/Autoprovince/amphures.json";
import subdistricts from "../../../Autoprovince/Autoprovince/districts.json";
import { apiClient, urlClientInfo } from "../../../api";
import { useLocation } from "react-router-dom";
import Visa from "../../../img/payment/visa-master card.png";
import InternetBanking from "../../../img/payment/mobile-banking 1.png";
import truemoneywallet from "../../../img/payment/truemoneywallet.png";
import promptpay from "../../../img/payment/prompt-pay-logo.png";
import Omiseprompay from "../../../components/Omise/Omiseprompay";
import TranserctionService from "../Service/transection";
import { TransectionErrorType } from "../../../models/transection";

const { Option } = Select;

class RequestTaxInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errormessage: "",
      order_id: null,
      order_invoice_id: null,
      packagename: null,
      packagecost: null,
      packagevat: null,
      packagecostwithvat: null,
      packagedetail: null,
      packageid: null,
      packagediscount: 0,
      btnLoginloading: false,
      omiseSource: null,
      /*isallowtopay: false,*/
      loading: true,
      statusmessage: "Preparing your order...",
      statusdescription: null,
      statusred: false,
      type: null,

      typetax: 1,
      name: null,
      mobile: null,
      branch: null,
      idcard: null,
      address: null,
      province: null,
      district: null,
      subdistrict: null,
      post: null,
      savedefault: true,
      new_taxvoice: true,
      taxvoice_id: null,

      taxInfoList: [],
      showDefault: false,
      showtaxinvoice: true,
      paymenttype: 4,

      statusdiscount: false,
      codediscount: null,
      keydiscount: null,
      namediscount: null,
      amounttypediscount: null,
      valuediscount: null,
      typediscount: null,

      discount: 0,
      discountpercent_text: "Discount",
      discountamounttype: "",
      canUsed: false,
      messagediscount: "",
      checkSubmit: false,
      loadingDiscount: false,
      showCreateInput: false,

      provinces_list: provinces,
      districts_list: districts,
      subdistricts_list: subdistricts,

      list_provinces: null,
      list_districts: null,
      list_subdistricts: null,

      district_placeholder: "กรุณาเลือกจังหวัดก่อน",
      subdistrict_placeholder: "กรุณาเลือกเขตก่อน",

      messageCheckPayment: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.getOrderDetail = this.getOrderDetail.bind(this);
    this.CreateCharge = this.CreateCharge.bind(this);
    this.ProcessCreditcardPayment = this.ProcessCreditcardPayment.bind(this);
    /*this.setAllowToPayStatus = this.setAllowToPayStatus.bind(this);*/
    this.redirecttopackagelist = this.redirecttopackagelist.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.getClientInfo = this.getClientInfo.bind(this);
    this.setType = this.setType.bind(this);
    this.getTaxinfos = this.getTaxinfos.bind(this);
    this.Discount = this.Discount.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.saveNewTaxinfo = this.saveNewTaxinfo.bind(this);
    this.CheckBtnPayment = this.CheckBtnPayment.bind(this);
    this.CorporateRequired = this.CorporateRequired.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleErrorMessage = this.handleErrorMessage.bind(this);
  }

  componentDidMount() {
    // !!------------------ เปลี่ยนเป็น free text ------------------!!
    // this.setState({
    //   list_provinces: this.state.provinces_list.map((row) => (
    //     <Option key={row.province_id} value={row.province_id}>
    //       {row.province_name}
    //     </Option>
    //   )),
    // });
    // !!------------------ เปลี่ยนเป็น free text ------------------!!
    this.getTaxinfos();
    this.getClientInfo();
    this.getOrderDetail();
  }

  getClientInfo() {
    urlClientInfo
      .get("", {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .then((response) => {
        this.setState({
          clientInfo: {
            ipString: response.data.ipString,
            userAgent: response.data.userAgent,
          },
        });
      })
      .catch((error) => {
        this.setState({ clientInfo: "" });
      });
  }

  getTaxinfos() {
    this.setState({
      loadingAll: true,
    });

    if (CheckTokenExp()) {
      window.location.href = "/logout";
    }

    let req = null;

    apiClient
      .post("api/Ecom/getTaxinfos", req, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        if (response.data.status == 999) {
          return;
        }

        if (response.data.status == 200 && response.data.taxinfos.length != 0) {
          this.setState({
            taxInfoList: response.data.taxinfos,
            showDefault: true,
            showtaxinvoice: false,
            new_taxvoice: false,
            taxvoice_id: response.data.taxinfos[0].id,
            typetax: response.data.taxinfos[0].type,
            name: response.data.taxinfos[0].name,
            mobile: response.data.taxinfos[0].mobile,
            branch: response.data.taxinfos[0].branch,
            idcard: response.data.taxinfos[0].id_card,
            address: response.data.taxinfos[0].address,
            province: response.data.taxinfos[0].province,
            district: response.data.taxinfos[0].district,
            subdistrict: response.data.taxinfos[0].subdistrict,
            post: response.data.taxinfos[0].post,
          });
        }
      })
      .catch((error) => {
        notification.open({
          message: "There is an error while trying to get Tax info data. ",
          description: "Please try again later",
          top: 60,
          className: "rti_form",
        });
      })
      .finally((data) => {
        this.setState({
          loadingAll: false,
        });
      });
  }

  async saveNewTaxinfo() {
    let req = JSON.stringify({
      OrderId: this.state.order_id,
      Taxinvoice: {
        taxvoice_id: this.state.taxvoice_id,
        type: this.state.typetax,
        name: this.state.name,
        mobile: this.state.mobile,
        idcard: this.state.idcard,
        address: this.state.address,
        branch: this.state.branch,
        province: this.state.province,
        district: this.state.district,
        subdistrict: this.state.subdistrict,
        post: this.state.post,
        new_taxvoice: this.state.new_taxvoice,
        savedefault: this.state.savedefault,
      }
    });
    let result = false
    await apiClient
      .post("api/Ecom/SaveTaxInvoiceByOderId", req, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        notification.open({
          message: "Tax info saved",
          description: "Your tax info will appeared on top in 3 second",
          top: 60,
          className: "rti_form g",
          duration: 3,
        });
        result = true
      })
      .catch((error) => {
        notification.open({
          message: "Error",
          description: error,
          top: 60,
          className: "rti_form r",
        });
      })
    return result
  }

  async getOrderDetail() {
    const pid = queryString.parse(this.props.location.search).pid

    const response = await TranserctionService.createOrFindOrder(pid)

    if (response.data.status == TransectionErrorType.ERROR) {
      this.redirecttopackagelist();
    } else if (response.message !== null) {
      this.setState({
        statusmessage: response.message.message,
        statusdescription: response.message.description,
        statusred: true,
      });
    } else {
      this.setState({
        order_id: response.data.order.id,
        order_invoice_id: response.data.order.invoice_no,
        packagename: response.data.order.package.PKG_NAME,
        packagecost: response.data.order.package.PKG_COST_INFO.PKGC_PRICE,
        packagedetail: response.data.order.package.PKG_DETAIL,
        packageid: response.data.order.package.PKG_ID,
        packagevat: response.data.order.package.PKG_VAT,
        packagecostwithvat: response.data.order.package.PKG_COST_INFO.PKGC_NET_PRICE,
        loading: false,
      });
    }
  }

  async CreateCharge(source, type) {
    const self = this
    const payload = {
      oid: self.state.order_id,
      source: source,
      types: type,
      urlRedirect: window.location.origin, // get only domain
      OrderId: this.state.order_id,
      Taxinvoice: {
        taxvoice_id: this.state.taxvoice_id,
        type: this.state.typetax,
        name: this.state.name,
        mobile: this.state.mobile,
        idcard: this.state.idcard,
        address: this.state.address,
        branch: this.state.branch,
        province: this.state.province,
        district: this.state.district,
        subdistrict: this.state.subdistrict,
        post: this.state.post,
        new_taxvoice: this.state.new_taxvoice,
        savedefault: this.state.savedefault,
      },
      client: this.state.clientInfo,
    };

    const saveTax = await self.saveNewTaxinfo()
    if (!saveTax) {
      return
    }

    if (self.state.keydiscount) {
      self.setDiscountSession()

      self.setState({
        discountlist: {
          netprice: self.state.packagecostwithvat,
          discountname: self.state.namediscount,
          discountamounttype: self.state.amounttypediscount,
          discountvalue: self.state.valuediscount,
          discounttype: self.state.typediscount,
          discountprice: self.state.packagediscount,
          discountcode: self.state.codediscount,
          discountkey: self.state.keydiscount,
        },
      });
      payload.discount = self.state.discountlist
    }

    const req = JSON.stringify(payload)

    const response = await TranserctionService.createCharge(req)

    if (response.data.status === TransectionErrorType.SUCCESS) {
      window.location = response.data.redirect;
    } else if (response.data.status !== TransectionErrorType.SUCCESS) {
      notification.open({
        message: String(response.data.message).replace("_", " "),
        description: response.data.description,
        top: 60,
        className: "rti_form r",
        duration: 10,
        onClose: () => { },
      });
      self.getOrderDetail();
    } else if (response.message !== null) {
      self.setState({
        statusmessage: response.message.message,
        statusdescription: response.message.description,
      });
    }
  }

  ProcessCreditcardPayment(chargetoken) {
    this.setState({
      taxinfo: {
        taxvoice_id: this.state.taxvoice_id,
        type: this.state.typetax,
        name: this.state.name,
        mobile: this.state.mobile,
        idcard: this.state.idcard,
        address: this.state.address,
        branch: this.state.branch,
        province: this.state.province,
        district: this.state.district,
        subdistrict: this.state.subdistrict,
        post: this.state.post,
        new_taxvoice: this.state.new_taxvoice,
        savedefault: this.state.savedefault,
      },
    });

    let req = "";
    if (this.state.keydiscount) {
      this.setState({
        discountlist: {
          netprice: this.state.packagecostwithvat,
          discountname: this.state.namediscount,
          discountamounttype: this.state.amounttypediscount,
          discountvalue: this.state.valuediscount,
          discounttype: this.state.typediscount,
          discountprice: this.state.packagediscount,
          discountcode: this.state.codediscount,
          discountkey: this.state.keydiscount,
        },
      });

      req = JSON.stringify({
        oid: this.state.order_id,
        token: chargetoken,
        client: this.state.clientInfo,
        TaxInfo: this.state.taxinfo,
        discount: this.state.discountlist,
      });
    } else {
      req = JSON.stringify({
        oid: this.state.order_id,
        token: chargetoken,
        client: this.state.clientInfo,
        TaxInfo: this.state.taxinfo,
      });
    }

    apiClient
      .post("api/ecom/processcreditcardpayment", req, {
        headers: {
          Authorization: `Bearer ${token} `,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        if (response.data.status != 300) {
          if (
            response.data.status == 802 ||
            response.data.status == 803 ||
            response.data.status == 804 ||
            response.data.status == 805
          ) {
            this.setState({
              statusmessage: response.data.message,
              statusdescription: response.data.description,
            });
            this.setLoading(false);
          } else {
            notification.open({
              message: String(response.data.message).replace("_", " "),
              description: response.data.description,
              top: 60,
              className: "rti_form r",
              duration: 10,
              onClose: () => { },
            });
            this.getOrderDetail();
          }
        } else if (response.data.status == 300) {
          this.props.history.push("/OrderSummary?rid=" + response.data.rid);
        }
      })
      .catch((error) => {
        this.setState({
          statusmessage:
            error.message +
            ". " +
            "If your card have been charged please contact support with order invoice id : " +
            this.state.order_invoice_id,
          statusdescription: error.description,
          statusred: true,
        });
      });
  }

  Discount() {
    this.setState({ checkSubmit: true });
    if (!CheckTokenExp()) {
      var code = this.state.codediscount;
      if (code) {
        this.setState({ loadingDiscount: true });

        apiClient
          .get("api/Ecom/Discount/" + code, {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          })
          .then((data) => {
            var json = data.data;

            if (json.canUsed != 1 && json.isExpire != 0) {
              this.setState({ canUsed: false });
              this.setState({ messagediscount: "Discount code can't used" });
            } else {
              this.setState({ keydiscount: json.key });
              this.setState({ canUsed: true });
              this.setState({ statusdiscount: true });
              this.setState({ namediscount: json.name });
              this.setState({ amounttypediscount: json.discountAmountType });
              this.setState({ valuediscount: json.dicount });
              this.setState({ typediscount: json.discountType });
              this.setState({ messagediscount: "" });
              if (json.discountAmountType == "PERCENT") {
                this.setState({
                  discountpercent_text:
                    "Discount" + " (" + json.dicount + "%" + ")",
                });
                this.setState({
                  packagediscount:
                    (this.state.packagecost * json.dicount) / 100,
                });
                var packagecostwithvat_val =
                  this.state.packagecost -
                  this.state.packagediscount +
                  this.state.packagevat;
                this.setState({
                  packagecostwithvat: packagecostwithvat_val,
                });
              } else {
                this.setState({ discountpercent_text: "Discount" });
                this.setState({ packagediscount: json.dicount });
                this.setState({
                  packagecostwithvat:
                    this.state.packagecost -
                    this.state.packagediscount +
                    this.state.packagevat,
                });
              }
            }

            this.setState({
              discount: json.dicount,
              discountamounttype: json.discountAmountType,
            });

            this.setState({ loadingDiscount: false });
          })
          .catch((error) => { });
      }
    } else {
      window.location.href = "logout";
    }
  }

  // ------------------- Session Tools ------------------- //

  setDiscountSession() {
    sessionStorage.setItem(
      "discount",
      JSON.stringify({
        netprice: this.state.packagecostwithvat,
        discountname: this.state.namediscount,
        discountamounttype: this.state.amounttypediscount,
        discountvalue: this.state.valuediscount,
        discounttype: this.state.typediscount,
        discountprice: this.state.packagediscount,
        discountcode: this.state.codediscount,
        discountkey: this.state.keydiscount,
      })
    );
  }

  setTaxinfoSession() {
    sessionStorage.setItem(
      "taxinfo",
      JSON.stringify({
        taxvoice_id: this.state.taxvoice_id,
        type: this.state.typetax,
        name: this.state.name,
        mobile: this.state.mobile,
        idcard: this.state.idcard,
        address: this.state.address,
        branch: this.state.branch,
        province: this.state.province,
        district: this.state.district,
        subdistrict: this.state.subdistrict,
        post: this.state.post,
        new_taxvoice: this.state.new_taxvoice,
        savedefault: this.state.savedefault,
      })
    );
  }

  // ------------------- Hadnle Tools ------------------- //
  handleChange(e) {
    if (e.target.name === "codediscount") {
      this.setState({ checkSubmit: false });
    }
    this.setState({ [e.target.name]: e.target.value.trim() });
  }

  handleChangeInput(name, value) {
    this.setState({ [name]: value, messageCheckPayment: "", errormessage: "" })
  }

  handleErrorMessage(message) {
    this.setState({ errormessage: message });
  }

  redirecttopackagelist() {
    window.location.href = packagelistPage;
  }

  setLoading(loading) {
    this.setState({ loading: loading });
  }

  setMessage(message, description = null, isred = false) {
    this.setState({
      statusmessage: message,
      statusdescription: description,
      statusred: isred,
    });
  }

  setUrl(url) {
    this.props.history.push(url);
  }

  setType(e) {
    document.getElementById("typeitext").style.color = "rgba(0, 0, 0, 0.65)";
    document.getElementById("typectext").style.color = "rgba(0, 0, 0, 0.65)";
    this.setState({
      type: e.target.value,
      branch: null,
    });
  }

  setShowCreateInput() {
    this.setState({ showCreateInput: !this.state.showCreateInput });
  }

  CheckBtnPayment(message) {
    this.setState({ loading: true });
    this.setState({ messageCheckPayment: message });
  }

  CorporateRequired() {
    if (this.state.typetax === 2) {
      return <span style={{ color: "red" }}>*</span>;
    } else {
      return <></>;
    }
  }

  onChangeCheckbox(e) {
    const value = e.target.checked
    this.setState({ savedefault: value });
  }

  render() {
    let {
      packagename,
      packagecost,
      packagedetail,
      packagevat,
      packagecostwithvat,
      loading,
      taxInfoList,
      showDefault,
      showtaxinvoice,
      packagediscount,
      codediscount,
      statusdiscount,
      messagediscount,
      order_invoice_id,
      statusmessage,
      statusdescription,
      statusred,
      typetax,
      type,
      name,
      branch,
      idcard,
      address,
      province,
      district,
      subdistrict,
      post,
      mobile,
      list_districts,
      list_subdistricts,
      discountpercent_text,
      discountamounttype,
      paymenttype,
      district_placeholder,
      subdistrict_placeholder,
      savedefault,
      new_taxvoice,
      canUsed,
      checkSubmit,
      loadingDiscount,
      messageCheckPayment,
    } = this.state;

    return (
      <div
        className="app flex-row orderdetailbg"
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      ><Spin spinning={loading}>
          <Row
            gutter={{ xs: 8, sm: 16, md: 16, lg: 32 }}
            style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", }}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={10}
              style={{ marginBottom: "16px" }}
            >
              <Card className="card-order">
                {loadingDiscount ? (
                  <div>
                    <Row className="">
                      <Col
                        className={
                          statusred
                            ? "justify-content-center order-status-error"
                            : "d-flex justify-content-center"
                        }
                      >
                        <Row className="text-center">
                          <Col>{statusmessage}</Col>
                        </Row>
                        {statusdescription != "" && statusdescription != null ? (
                          <Row>
                            <Col className="text-center">{statusdescription}</Col>
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col className="d-flex justify-content-center">
                        <Spin size="large" />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <h2>Your Selected Package</h2>
                    <div
                      style={{
                        padding: "0px 10px",
                        flexWrap: "wrap",
                        border: "1px solid #d9d9d9",
                        borderRadius: "4px",
                        marginBottom: "16px",
                      }}
                    >
                      <Row gutter={8}>
                        <Col span={19}>
                          <div style={{ fontWeight: "bold" }}>
                            {packagename + "-" + packagedetail}
                          </div>
                        </Col>
                        <Col span={5}>
                          <div
                            style={{
                              color: "#EA7326",
                              cursor: "pointer",
                              float: "right",
                              textAlign: "right",
                            }}
                            onClick={() => this.props.history.push("PackageList")}
                          >
                            Change{">"}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Input
                        style={{
                          width: "100%",
                          marginBottom: "16px",
                          marginRight: "10px",
                        }}
                        className={checkSubmit && !canUsed ? "discountError" : ""}
                        value={codediscount}
                        name="codediscount"
                        onChange={this.handleChange}
                        placeholder="Redeem Code"
                        disabled={true}
                        suffix={
                          checkSubmit ? (
                            <>
                              {canUsed ? (
                                <Icon
                                  type="check-circle"
                                  theme="filled"
                                  style={{ color: "#20C29C" }}
                                />
                              ) : (
                                <Icon
                                  type="exclamation-circle"
                                  theme="filled"
                                  style={{ color: "#F8CF40" }}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )
                        }
                      />
                      <Button disabled={true} onClick={() => this.Discount()}>Submit</Button>
                    </div>
                    <Divider style={{ margin: "5px 0px" }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography>Subtotal</Typography>
                        <Typography>{discountpercent_text}</Typography>
                        <Typography>VAT 7%</Typography>
                        <Typography
                          style={{ fontSize: "22px", fontWeight: "bold" }}
                        >
                          NET AMOUNT
                        </Typography>
                      </div>
                      <div className="order-card-right">
                        <Typography>
                          {formatCurrency(parseFloat(packagecost).toFixed(2)) +
                            " Baht"}
                        </Typography>
                        <Typography>
                          {statusdiscount == true ? (
                            <span style={{ color: "red" }}>
                              -{" "}
                              {discountamounttype != "PERCENT"
                                ? formatCurrency(
                                  parseFloat(packagediscount).toFixed(2)
                                )
                                : formatCurrency(
                                  parseFloat(packagediscount).toFixed(2)
                                )}
                            </span>
                          ) : (
                            <span>
                              {discountamounttype != "PERCENT"
                                ? formatCurrency(
                                  parseFloat(packagediscount).toFixed(2)
                                )
                                : formatCurrency(
                                  parseFloat(packagediscount).toFixed(2)
                                )}
                            </span>
                          )}
                          &nbsp;Baht
                        </Typography>
                        <Typography>
                          {formatCurrency(parseFloat(packagevat).toFixed(2)) +
                            " Baht"}
                        </Typography>
                        <Typography
                          style={{ fontSize: "22px", fontWeight: "bold" }}
                        >
                          {formatCurrency(
                            parseFloat(packagecostwithvat).toFixed(2)
                          ) + " Baht"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ marginBottom: "16px" }}
                >
                  <Card className="card-order">
                    <h2 style={{ marginBottom: "20px" }}>
                      Select Payment Method
                    </h2>

                    <Radio.Group onChange={(e) => this.setState({ paymenttype: e.target.value })} value={paymenttype}>
                      <Row gutter={8}>
                        {/* ------------  Credit/ Debit Card ------------ */}
                        {/* <Col xs={24} sm={12} lg={12}>
                          <Radio
                            value={1}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Card
                              className={
                                paymenttype === 1
                                  ? "payment-card payment-card-active"
                                  : "payment-card"
                              }
                            >
                              <div className="payment-card-body">
                                <div>Credit/ Debit Card</div>
                                <img
                                  src={Visa}
                                  alt=""
                                  style={{ width: "55px", height: "18px" }}
                                />
                              </div>
                            </Card>
                          </Radio>
                        </Col> */}
                        {/* ------------  Internet Banking ------------ */}
                        {/* <Col xs={24} sm={12} lg={12}>
                          <Radio
                            value={2}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Card
                              className={
                                paymenttype === 2
                                  ? "payment-card payment-card-active"
                                  : "payment-card"
                              }
                            >
                              <div className="payment-card-body">
                                <div>Internet Banking</div>
                                <img
                                  src={InternetBanking}
                                  alt=""
                                  style={{ width: "26px", height: "26px" }}
                                />
                              </div>
                            </Card>
                          </Radio>
                        </Col> */}
                        {/* ------------  TrueMoney ------------ */}
                        {/* <Col xs={24} sm={12} lg={12}>
                          <Radio
                            value={3}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Card
                              className={
                                paymenttype === 3
                                  ? "payment-card payment-card-active"
                                  : "payment-card"
                              }
                            >
                              <div className="payment-card-body">
                                <div>TrueMoney</div>
                                <img
                                  src={truemoneywallet}
                                  alt=""
                                  style={{ width: "48px", height: "28px" }}
                                />
                              </div>
                            </Card>
                          </Radio>
                        </Col> */}
                        <Col xs={24} sm={12} lg={12}>
                          <Radio
                            value={4}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Card
                              className={
                                paymenttype === 4
                                  ? "payment-card payment-card-active"
                                  : "payment-card"
                              }
                            >
                              <div className="payment-card-body">
                                <div>PromptPay</div>
                                <img
                                  src={promptpay}
                                  alt=""
                                  style={{ width: "60px", height: "20px" }}
                                />
                              </div>
                            </Card>
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Card>
                </Col>
                {showDefault && (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ marginBottom: "16px" }}
                    className={showtaxinvoice ? "showTaxInvoice" : ""}
                  >
                    <Card className="card-order">
                      <Row>
                        {taxInfoList.map((row) => (
                          <Col
                            id={row.id}
                            lg={24}
                            xl={24}
                            style={{ height: "100%" }}
                          >
                            <Row gutter={16} type="flex" ustify="start">
                              <Col className="form-check form-check-inline">
                                <h5>Default Tax Invoice</h5>
                              </Col>
                            </Row>
                            <Divider style={{ margin: "5px 0px 15px" }} />
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td>
                                  <label
                                    style={{ width: "100%" }}
                                    className="form-check-label"
                                    htmlFor={"taxinforadio-" + row.id}
                                  >
                                    <div>
                                      <Col xs={24} sm={24} md={24} lg={12}>
                                        <span style={{ fontWeight: "bold" }}>
                                          Type
                                        </span>{" "}
                                        :{" "}
                                        {row.type == 1
                                          ? "Individual"
                                          : "Corporate"}
                                      </Col>
                                      <Col xs={24} md={24} lg={12}>
                                        <span style={{ fontWeight: "bold" }}>
                                          ID card/ Tax id number
                                        </span>{" "}
                                        : {row.id_card}
                                      </Col>
                                    </div>
                                    <div>
                                      <Col xs={24} md={24} lg={12}>
                                        <span style={{ fontWeight: "bold" }}>
                                          Contact Name
                                        </span>{" "}
                                        : {row.name}
                                      </Col>
                                    </div>
                                    <div>
                                      <Col xs={24} md={24} lg={12}>
                                        <span style={{ fontWeight: "bold" }}>
                                          Branch
                                        </span>{" "}
                                        : {row.branch != "" ? row.branch : "-"}
                                      </Col>
                                      <Col xs={24} md={24} lg={12}>
                                        <span style={{ fontWeight: "bold" }}>
                                          Mobile
                                        </span>{" "}
                                        : {row.mobile}
                                      </Col>
                                    </div>
                                    <div>
                                      <Col xs={24} md={24} lg={12}>
                                        <span style={{ fontWeight: "bold" }}>
                                          Address
                                        </span>{" "}
                                        : {row.address} {row.subdistrict}{" "}
                                        {row.district} {row.province} {row.post}
                                      </Col>
                                    </div>
                                  </label>
                                </td>
                              </tr>
                            </table>
                            <Divider style={{ margin: "8px 0px" }} />
                            <Row gutter={16} type="flex" ustify="start">
                              <Col className="form-check form-check-inline">
                                <span>or &nbsp;</span>
                                <span
                                  onClick={() =>
                                    this.setState({
                                      showtaxinvoice: true,
                                      new_taxvoice: false,
                                    })
                                  }
                                  style={{
                                    color: "#097FF5",
                                    fontWeight: 700,
                                    cursor: "pointer",
                                  }}
                                >
                                  Edit Tax invoice
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Card>
                  </Col>
                )}
                {showtaxinvoice && (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ marginBottom: "16px" }}
                  >
                    <Card className="card-order">
                      <Col xs={24} lg={24} xl={24}>
                        <h2>Tax Invoice</h2>
                        <Divider style={{ margin: "5px 0px 15px" }} />
                        {messageCheckPayment && (
                          <div style={{ color: "red", marginBottom: "8px" }}>
                            *{messageCheckPayment}
                          </div>
                        )}
                        <Radio.Group
                          onChange={(e) =>
                            this.handleChangeInput("typetax", e.target.value)
                          }
                          value={typetax}
                        >
                          <Row gutter={8}>
                            <Col xs={24} sm={12} lg={12}>
                              <Radio
                                value={1}
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Card
                                  className={
                                    typetax === 1
                                      ? "payment-card payment-card-active"
                                      : "payment-card"
                                  }
                                >
                                  <div className="payment-card-body">
                                    <div>Individual</div>
                                  </div>
                                </Card>
                              </Radio>
                            </Col>
                            <Col xs={24} sm={12} lg={12}>
                              <Radio
                                value={2}
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Card
                                  className={
                                    typetax === 2
                                      ? "payment-card payment-card-active"
                                      : "payment-card"
                                  }
                                >
                                  <div className="payment-card-body">
                                    <div>Corporate</div>
                                  </div>
                                </Card>
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                        <Row gutter={16}>
                          <Col xs={24} className="form-group-sm">
                            <label forhtml="contactname">
                              Contact Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              maxLength={300}
                              placeholder="Contact name"
                              id="contactname"
                              type="text"
                              value={name}
                              onChange={(e) =>
                                this.handleChangeInput("name", e.target.value)
                              }
                            />
                          </Col>
                          {typetax === 2 && (
                            <Col xs={24} lg={12} className="form-group-sm">
                              <label forhtml="mobile">
                                Branch<span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                maxLength="15"
                                placeholder="Branch"
                                id="branch"
                                type="text"
                                value={branch}
                                onChange={(e) =>
                                  this.handleChangeInput("branch", e.target.value)
                                }
                              />
                            </Col>
                          )}
                          <Col xs={24} lg={12} className="form-group-sm">
                            <label forhtml="mobile">
                              Mobile<span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              maxLength={10}
                              placeholder="Mobile"
                              id="mobile"
                              type="text"
                              value={mobile}
                              onChange={(e) =>
                                this.handleChangeInput("mobile", e.target.value.replace(/[^\d]/g, ""))
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12} className="form-group-sm">
                            <label forhtml="idcard">
                              ID Card/Tax ID Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              maxLength={13}
                              placeholder="ID card / Tax ID number"
                              id="idcard"
                              type="text"
                              value={idcard}
                              onChange={(e) =>
                                this.handleChangeInput("idcard", e.target.value.replace(/[^\d]/g, ""))
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12} className="form-group-sm">
                            <label forhtml="address">
                              Address
                              {typetax === 2 && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <Input
                              maxLength={500}
                              placeholder="Address"
                              id="address"
                              type="text"
                              value={address}
                              onChange={(e) =>
                                this.handleChangeInput("address", e.target.value)
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12} className="form-group-sm">
                            <label forhtml="province">
                              Province{this.CorporateRequired()}
                            </label>
                            <Input
                              maxLength={500}
                              placeholder="Province"
                              id="province"
                              type="text"
                              value={province}
                              onChange={(e) =>
                                this.handleChangeInput("province", e.target.value)
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12} className="form-group-sm">
                            <label forhtml="district">
                              District{this.CorporateRequired()}
                            </label>
                            <Input
                              maxLength={500}
                              placeholder="District"
                              id="district"
                              type="text"
                              value={district}
                              onChange={(e) =>
                                this.handleChangeInput("district", e.target.value)
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12} className="form-group-sm">
                            <label forhtml="subdistrict">
                              Subdistrict{this.CorporateRequired()}
                            </label>
                            <Input
                              maxLength={500}
                              placeholder="Subdistrict"
                              id="subdistrict"
                              type="text"
                              value={subdistrict}
                              onChange={(e) =>
                                this.handleChangeInput("subdistrict", e.target.value)
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <label forhtml="postal">
                              Postal code{this.CorporateRequired()}
                            </label>
                            <Input
                              maxLength={5}
                              placeholder="Postal code"
                              id="postal"
                              type="text"
                              value={post}
                              onChange={(e) =>
                                this.handleChangeInput("post", e.target.value)
                              }
                            />
                          </Col>
                          <Col xs={24}>
                            <Divider style={{ marginBottom: "8px" }} />
                          </Col>
                          <Col xs={12} lg={12} style={{ textAlign: "left" }}>
                            <Checkbox
                              name="savedefault"
                              checked={savedefault}
                              onChange={this.onChangeCheckbox}
                            >
                              Save as Default
                            </Checkbox>
                          </Col>
                          {(showtaxinvoice && !new_taxvoice) && (
                            <Col xs={12} lg={12} style={{ textAlign: "right" }}>
                              <Button
                                onClick={() =>
                                  this.setState({
                                    showtaxinvoice: false,
                                    showDefault: true,
                                  })
                                }
                              >
                                Cancel
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Card>
                  </Col>
                )}
                <Col xs={24} lg={24} xl={24} style={{ textAlign: "right" }}>
                  {paymenttype === 1 ? (
                    <OmiseCreditCard
                      AutoCheckout={true}
                      ProcessPayment={this.ProcessCreditcardPayment}
                      packagecost={packagecostwithvat | 0}
                      setLoading={this.setLoading}
                      setMessage={this.setMessage}
                      data={this.state}
                      CheckBtnPayment={this.CheckBtnPayment}
                      showtaxinvoice={this.state.showtaxinvoice} //edit tax invoice
                    />
                  ) : paymenttype === 2 ? (
                    <OmiseInternetBanking
                      AutoCheckout={true}
                      ProcessPayment={this.CreateCharge}
                      packagecost={packagecostwithvat | 0}
                      setLoading={this.setLoading}
                      setMessage={this.setMessage}
                      data={this.state}
                      CheckBtnPayment={this.CheckBtnPayment}
                      showtaxinvoice={this.state.showtaxinvoice} //edit tax invoice
                    />
                  ) : paymenttype === 3 ? (
                    <OmiseTrueMoney
                      AutoCheckout={true}
                      ProcessPayment={this.CreateCharge}
                      packagecost={packagecostwithvat | 0}
                      setLoading={this.setLoading}
                      setMessage={this.setMessage}
                      data={this.state}
                      CheckBtnPayment={this.CheckBtnPayment}
                      showtaxinvoice={this.state.showtaxinvoice} //edit tax invoice
                    />
                  ) : (
                    <Omiseprompay
                      AutoCheckout={true}
                      ProcessPayment={this.CreateCharge}
                      packagecost={packagecostwithvat | 0}
                      setLoading={this.setLoading}
                      setMessage={this.setMessage}
                      handleErrorMessage={this.handleErrorMessage}
                      data={this.state}
                      CheckBtnPayment={this.CheckBtnPayment}
                      showtaxinvoice={this.state.showtaxinvoice} //edit tax invoice
                    />
                  )
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default RequestTaxInfo;
