import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { apiClient } from "../../../api";
import CardNormal from "../PackageListSale/CardPackage/CardNormal";
import CardRecommend from "../PackageListSale/CardPackage/CardRecommend";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css';
import { Navigation, Pagination, Mousewheel } from "swiper";

export default function PackageListSale() {
  let history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const [data, setData] = useState([]);

  const onClickToPage = (id) => {
    const encodedData = btoa(id); // encode a string
    history.push("login?pid=" + encodedData);
  };

  const fetchData = async () => {
    let req = null;

    apiClient.post("api/ecom/getPackageSale", req, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        setData(response.data.PackageList);
      })
      .catch((error) => { });
  };

  return (
    <div className="app" style={{ minHeight: 0 }}>
      <div className="package">
        <div>
          <p
            style={{
              textAlign: "center",
              margin: "20px 0px",
              fontSize: "3rem",
              fontWeight: "bold",
            }}
          >
            How many SMS do you plan to send?
          </p>
        </div>
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Swiper
            spaceBetween={25}
            navigation={true}
            mousewheel={true}
            pagination={false}
            grabCursor={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 2,
              },
              1366: {
                slidesPerView: 3,
              },
              1920: {
                slidesPerView: 5,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel]}
            className="mySwiper"
          >
            {data && data.length > 0 &&
              data.map((obj, index) => {
                if (obj.PackageType === 1 && obj.Package_Recommend) {
                  return <SwiperSlide><CardRecommend key={index + 1} obj={obj} onClickToPage={onClickToPage} /></SwiperSlide>
                } else if (obj.PackageType === 1 && !obj.Package_Recommend) {
                  return <SwiperSlide><CardNormal key={index + 1} obj={obj} onClickToPage={onClickToPage} /></SwiperSlide>
                }
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
