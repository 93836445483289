import React, { useState, useEffect } from "react";
import { Input, Col, Row, Icon, Button } from "antd";
import { apiClient, urlClientInfo } from "../../../../api";

export default function Otp({ callRegisterApi, state, setState, model, setModel, callGetOTP }) {

  useEffect(() => {

  }, []);

  const callVerifyOtp = () => {
    let req = {
      otpid: model.OTPid,
      otpcode: model.OTPcode,
    };

    apiClient
      .post("api/accountregister/verifyotp", req, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .then((response) => {
        if (response.data.verifyResult) {
          callRegisterApi();
        } else {
          setState({
            ...state,
            otpErrorMessage: response.data.message,
            showOtpErrorMessage: true,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          otpErrorMessage: error.message,
          showOtpErrorMessage: true,
        });
      });
  };

  return (
    <Row className="justify-content-center">
      <div className="card-login">
        <span style={{ fontSize: "36px", fontWeight: "600", }}>
          Otp
        </span>
        {state.showOtpErrorMessage && (
          <div className="" xs="12" style={{ textAlign: "left" }}>
            <label for={state.otpErrorMessage} style={{ color: "red" }}>
              {state.otpErrorMessage}
            </label>
          </div>
        )}
        <div>REF CODE : {state.OTPref}</div>
        <Input
          placeholder="OTP CODE"
          style={{ marginBottom: "16px" }}
          value={model.OTPcode}
          onChange={(e) =>
            setModel({
              ...model,
              OTPcode: e.target.value.trim(),
            })
          }
        />
        <Button
          type="primary"
          style={{ marginBottom: "16px" }}
          onClick={callVerifyOtp}
          block
        >
          Verify OTP
        </Button>
        <Button onClick={callGetOTP}>Resend OTP</Button>
      </div>
    </Row>
  );
}
