import React from "react";
import {
  Card, Col, Row,
} from "reactstrap";

export default function CardDemo({ obj, onClickToPage, formatCurrency, ClearCookie }) {
  return (
    <Card className="card-package">
      <h4 className="card-title">Demo Trial</h4>
      <p className="sub-title">Free</p>
      <div className="card-content">
        <div className="text-desc">
          <div className="box"></div>
          <span style={{ fontSize: "26px" }}>
            {formatCurrency(obj.Package_Creditstart)} Messages
          </span>
        </div>
        <div className="text-desc">
          <div className="box"></div>
          <span style={{ fontSize: "26px" }}>
            {obj.PackagePrice_per_credit} THB/Message
          </span>
        </div>
        <div className="text-desc">
          <div className="box"></div>
          <span style={{ fontSize: "26px" }}>No Limit</span>
        </div>
        <div className="text-desc">
          <div className="box"></div>
          <span style={{ fontSize: "26px" }}>
            {obj.Package_Sender} Sender Name
          </span>
        </div>
        <div className="text-desc">
          <div className="box"></div>
          <span style={{ fontSize: "26px" }}>
            {formatCurrency(obj.Package_People)} Maximum people
          </span>
        </div>
      </div>
      <div style={{ margin: "0px 20px 20px" }}>
        <Card
          className={"button-black"}
          onClick={() => {
            ClearCookie();
            let pid =
              obj.PackageId + "|" + obj.Package_Creditstart;
            onClickToPage(pid, obj.PackageType);
          }}
        >
          <h5 style={{ color: "#fff", margin: "0px" }}>
            Start Free Trial
          </h5>
        </Card>
      </div>
    </Card>
  );
}
