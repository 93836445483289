import React, { useState, useEffect } from "react";
import {
  Card, Col, Row,
} from "reactstrap";
import { token, CheckTokenExp } from '../../../../config';
import { apiClient } from "../../../../api";
import { Slider, InputNumber, Input, Modal, Button } from "antd";
import ModalErrorWarning from '../../../../components/Ants/Modal/ModalErrorWaring';

export default function CardSlider(props) {

  const [creditMin, setCreditMin] = useState(1);
  const [creditRate, setCreditRate] = useState(0.5); // default credit rate
  const [maxCredit, setMaxCredit] = useState(1000000);
  const [errorWarningModalVisible, setErrorWarningModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState("Purchases cannot exceed the amount of 450,000.");

  const getProfile = () => {
    if (!CheckTokenExp()) {
      const req = null

      apiClient.post('api/profile/GetProfile', req
        , {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
          }
        })
        .then(data => {
          var json = data.data[0];

          if (json) {
            const creditRateUser = json.creditRate;
            setCreditRate(creditRateUser);
            setModel({
              inputValue: creditMin * 10000,
              val: creditMin,
              price: (creditMin * 10000) * creditRateUser,
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      window.location.href = "logout";
    }
  }

  useEffect(() => {
    getProfile();
  }, []);

  const [model, setModel] = useState({
    inputValue: creditMin * 10000,
    val: creditMin,
    price: (creditMin * 10000) * creditRate,
  });

  const handleCancel = () => {
    props.setShowModal(false);
    setModel({
      inputValue: creditMin * 10000,
      val: creditMin,
      price: (creditMin * 10000) * creditRate,
    });
  };

  const marks = {
    0: "",
    1: "10,000",
    10: "100,000",
    20: "200,000",
    30: "300,000",
    40: "400,000",
    50: "500,000",
    60: "600,000",
    70: "700,000",
    80: "800,000",
    90: "900,000",
    100: "1,000,000",
  };

  const onChangeSlider = (value) => {
    setModel({
      inputValue: value * 10000,
      val: value,
      price: (value * 10000) * creditRate,
    });
  };

  const onChangeInputCredit = (value) => {
    setModel({
      inputValue: value,
      val: value / 10000,
      price: value * creditRate,
    });
  };

  const onChangeInputPrice = (value) => {
    setModel({
      inputValue: value / creditRate,
      val: (value / creditRate) / 10000,
      price: value,
    });
  };

  const buyPackage = () => {
    if (model.price >= 470000) {
      props.setShowModal(false);
      errorWarningModal(true);
      return
    }
    const pid = `custom|${model.price}|${model.inputValue}`;
    const encodedData = btoa(pid);
    props.history.push("RequestTaxInfo?pid=" + encodedData);
    window.location.reload();
  }

  const errorWarningModal = (value) => {
    setErrorWarningModalVisible(value);
  }

  return (
    <div>
      <ModalErrorWarning
        loading={loading}
        errorWarningModalVisible={errorWarningModalVisible}
        errorWarningModal={errorWarningModal}
        messageError={messageError}
      />

      <Modal
        zIndex={9999}
        visible={props.showModal}
        footer={null}
        width={900}
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.45)" }}
      >
        <h3 className="card-title">Custom Package</h3>
        <div className="card-content package" style={{ paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px" }}>
          <div className="message-volume">
            <Slider
              style={{ width: "100%" }}
              tooltipVisible={false}
              marks={marks}
              onChange={onChangeSlider}
              value={typeof model.val === "number" ? model.val : 0}
            />
          </div>
          <div className="text-center mt-2 mb-1">
            <span style={{ color: "#B9B9C3", fontSize: "25px" }}>or</span>
          </div>
          <div className="text-center mb-3">
            <span style={{ color: "#19164f", fontSize: "26px" }}>Enter the number of credit you want.</span>
          </div>
          <div className="text-center mb-4">
            <InputNumber
              className="ants-input-number-normal w-50 text-center"
              style={{ fontSize: "50px", height: "48px" }}
              min={creditMin * 10000}
              max={maxCredit}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={model.inputValue}
              onChange={onChangeInputCredit}
            />
          </div>
          <div className="text-center mb-2">
            <span style={{ color: "#19164F", fontSize: "50px" }}>Price</span>
            <InputNumber
              className="text-center fz3rem w-25"
              style={{ fontSize: "50px", height: "48px", borderBottom: "2px solid #19164F" }}
              min={(creditMin * 10000) * creditRate}
              max={maxCredit * creditRate}
              formatter={(value) =>
                `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={model.price}
              onChange={onChangeInputPrice}
            />
            <span style={{ fontSize: "50px", color: "#19164F" }}> ฿</span>
          </div>
          <div className="w-100 d-flex text-center" style={{ justifyContent: "center" }}>
            <Card
              className={"button-gray w-25 mr-1"}
              onClick={handleCancel}
            >
              <h2 style={{ color: "white", margin: "0px", fontSize: "25px" }}>Cancle</h2>
            </Card>
            <Card
              className={"button-orange w-25"}
              onClick={buyPackage}
            >
              <h2 style={{ color: "white", margin: "0px", fontSize: "25px" }}>Buy now</h2>
            </Card>
          </div>
        </div>
      </Modal>
    </div>
  );
}
