import React, { useState, useEffect } from "react";
import { InputGroup, Input, Label } from 'reactstrap';
import { Button, Icon, Input as InputAntd, Row, Col } from 'antd';

export default function TwoFactor({ handleChange, _handleKeyPress, errormessage, state, setState, history, handleWarning, handleVerifyOTP, btnLoginloading }) {

  useEffect(() => {

  }, []);

  return (
    <div className="card-login">
      <div className="login-title">
        <h1 className="login-titel2">Two-Factor Authentication</h1>
      </div>
      <div className="login-content">
        <div>
          <p><span className="fa fa-envelope"></span> &nbsp;&nbsp; Please enter the 6-digit one-time password (OTP) sent to your email address
            within 5 minutes. You have 3 attempts to enter the correct code.</p>
          <h5>Authentication Code</h5>
          <p />
          <InputGroup className="">
            <input type="text" className="input-login"
              name="otp" placeholder="Enter OTP Code" onChange={handleChange} onKeyPress={_handleKeyPress} />
          </InputGroup>
          {errormessage ? <label htmlFor={errormessage} style={{ color: "red" }}>{errormessage}</label> : null}
          <Col xs={24} md={24} lg={16} xl={12}>
            <div className="checkbox-container" style={{ marginLeft: "10px", marginBottom: "20px" }}>
              <Input
                className="form-check-input"
                type="checkbox"
                id="consent"
                name="consent"
                checked={state.consent}
                onChange={handleChange}
              />
              <Label check className="form-check-label" htmlFor="consent">
                Remember this device for 7 days
              </Label>
            </div>
          </Col>
          <Row className="button-row">
            <Button
              className="resend-button"
              type="success"
              onClick={handleWarning}
              style={{ marginRight: "10px" }}
            >
              Resend Code
            </Button>
            <Button
              className="verify-button"
              type="primary"
              loading={btnLoginloading}
              onClick={handleVerifyOTP}
            >
              Verify
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
}
