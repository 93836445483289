import React, { Component } from "react";
import { Button, Icon, Input as InputAntd, Row, Col } from 'antd';
import { apiClient, urlgetip, urlClientInfo } from "../../../api";
import {
    set_cookie,
    getExpiredDate,
    parseJwt
} from "../../../config";
import queryString from "query-string";
import TwoFactor from "./components/twoFactor";
import ChangePassword from "./components/changePassword";
import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalBroadCastWarning from '../../../components/Ants/Modal/ModalBroadCastWarning';

export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btnLoginloading: false,
            username: "",
            password: "",
            errormessage: "",
            showPassword: false,
            ip: "",
            clientInfo: "",
            statusDefaultPassword: false,
            typeUser: 1,
            statusTwoFactor: false,
            email: "",
            otp: "",
            consent: false,
            token: "",
            otpId: "",
            cookie: "",
            successModalVisible: false,
            warningModalVisible: false,
            warningModalMessage: "",
            warningModalDescription: "",
            messageSuccess: "",
            warningModalCancel: () => { },
            warningModalOk: () => { },
            settingJson: {
                env: {
                    title: "Loading...",
                    name: "ants",
                    imgFolder: "ANTS",
                },
                imgpath: null,
                classBackground: null
            },
        };

        this.getCookie = this.getCookie.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.utilIp = this.utilIp.bind(this);
        this.getClientInfo = this.getClientInfo.bind(this);
        this.setUrl = this.setUrl.bind(this);
        this.handleRequestOTP = this.handleRequestOTP.bind(this);
        this.successModal = this.successModal.bind(this);
        this.handleWarning = this.handleWarning.bind(this);
        this.handelDefaultPassword = this.handelDefaultPassword.bind(this);
        this.handleVerifyOTP = this.handleVerifyOTP.bind(this);
        this.handleResendEmail = this.handleResendEmail.bind(this);
        this.getSettingJson = this.getSettingJson.bind(this);
    }

    componentDidMount() {
        this.getCookie("__AntiXsrfUerkey");
        this.utilIp();
        this.getClientInfo();
        this.getSettingJson();
    }

    getSettingJson() {
        apiClient.get('api/function/getappsettings')
            .then((response) => {
                const json = response.data;

                this.setState({ settingJson: { ...this.state.settingJson, env: json.env } })

                const file = require(`../../../../src/img/${json.env.imgFolder}/Logo_Web.png`)
                this.setState({ settingJson: { ...this.state.settingJson, imgpath: file } })

                switch (json.env.name) {
                    case "ANTS":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-ants-background" } })
                        break;
                    case "YING":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-ants3-background" } })
                        break;
                    case "SMS Up":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-ants4-background" } })
                        break;
                    case "Promotech":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-promotech-background" } })
                        break;
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                if (name == "__AntiXsrfUerkey=") {
                    if (queryString.parse(this.props.location.search).pid === undefined) {
                        if (
                            queryString.parse(this.props.location.search).oid === undefined &&
                            queryString.parse(this.props.location.search).rid === undefined
                        ) {

                            window.location.href = window.Configs.productlistPage;
                        } else if (queryString.parse(this.props.location.search).oid != undefined) {

                            this.setUrl("/RequestTaxInfo" + this.props.location.search);
                        } else if (queryString.parse(this.props.location.search).rid != undefined) {

                            this.setUrl("/OrderSummary" + this.props.location.search);
                        } else {

                            this.setUrl("/404");
                        }
                    } else {
                        this.setUrl("/RequestTaxInfo" + this.props.location.search);
                    }
                }
            }
        }
        return "";
    }

    handleLogin(e) {
        e.preventDefault();
        this.setState({ btnLoginloading: true });
        this.setState({ errormessage: "" });

        if (this.state.username != "" && this.state.password != "") {

            let req = JSON.stringify({
                "username": this.state.username,
                "password": this.state.password,
                "expireMinutes": "60",
                "ip": this.state.ip,
                "device": this.state.clientInfo,
            });

            apiClient.post('api/Token', req,
                { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
                .then(data => {
                    var json = data;
                    let Token = "__AntiXsrfUerkey=" + encodeURIComponent(json.data);
                    let ExpiredDate = getExpiredDate(Token).toGMTString();
                    let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";

                    var tokenReplace = SendToken.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
                    var payload = parseJwt(tokenReplace);

                    this.setState({ token: json.data, cookie: Token });

                    if (payload.defaultPassword === "True") {
                        this.handelDefaultPassword(payload)
                    } else if (payload.twofactor === "True") {
                        this.handleTwoFactor(payload, SendToken);
                    } else {
                        set_cookie(SendToken);
                        this.props.history.push("/dashboard");
                    }
                })
                .catch(error => {
                    this.setState({ errormessage: 'Username or Password is incorrect.' });
                    this.setState({ btnLoginloading: false });
                });
        } else {
            this.setState({ errormessage: "Username or Password is incorrect." });
            setTimeout(() => {
                this.setState({ btnLoginloading: false });
            }, 10);
        }

        if (!this.state.password) {
            this.inputpassword.focus();
        }
        if (!this.state.username) {
            this.inputusername.focus();
        }
    }

    handleTwoFactor(payload, SendToken) {
        if (payload.twofactor_7day === "True") {
            if (payload.twofactor_7day_last_date) {
                const lastDate = new Date(payload.twofactor_7day_last_date);
                const currentDate = new Date();
                lastDate.setHours(0, 0, 0, 0);
                currentDate.setHours(0, 0, 0, 0);

                const diffTime = currentDate - lastDate;
                const diffDays = diffTime / (1000 * 60 * 60 * 24);
                if (diffDays >= 7) {
                    this.handleRequestOTP();
                } else {
                    set_cookie(SendToken);
                    this.props.history.push("/dashboard");
                }
            }
        } else {
            this.handleRequestOTP();
        }
        this.setState({ btnLoginloading: false });
    }

    handelDefaultPassword(payload) {
        this.setState({ email: payload.email })
        this.forgotpwd(payload.unique_name, payload.primarysid, payload.email)
        this.state.statusDefaultPassword = true
        this.setState({ btnLoginloading: false });
    }

    forgotpwd(username, userId, email) {
        let req = JSON.stringify({
            username,
            userId,
            email,
            ip: this.state.ip,
            device: this.state.clientInfo
        });

        apiClient.post('api/usersmanage/forgetpassword', req
            , {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {
                this.setState({ btnLoginloading: false });
            })
            .catch(error => {
                this.setState({ btnLoginloading: false });
            });
    }

    // ------ Util ------ //

    handleChange(e) {
        const target = e.target;

        this.setState({ errormessage: "" });
        if (target.type === 'checkbox') {
            this.setState({ [target.name]: target.checked });
        } else {
            this.setState({ [target.name]: target.value });
        }
    }

    _handleKeyPress(e) {
        if (e.key === "Enter") {
            this.handleLogin(e);
        }
    }

    handleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    setUrl(url) {
        this.props.history.push(url);
    }

    utilIp() {
        urlgetip
            .get("", {
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
            .then((data) => {
                var json = data.data;
                this.setState({ ip: json });
            })
            .catch((error) => {
                this.setState({ ip: "" });
            });
    }

    getClientInfo() {
        urlClientInfo
            .get("", {
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
            .then((data) => {
                var json = data.data;
                this.setState({ clientInfo: JSON.stringify(json) });
            })
            .catch((error) => {
                this.setState({ clientInfo: "" });
            });
    }

    handleRequestOTP() {
        this.setState({ btnLoginloading: true });
        let req = JSON.stringify({});
        this.state.statusTwoFactor = true

        apiClient.post('api/RequestOTP', req
            , {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {
                if (data.status === 200) {
                    this.setState({ otpId: data.data.data });
                    this.setState({ btnLoginloading: false });
                    this.setState({ warningModalVisible: false });
                }
            })
            .catch(error => {
                this.setState({ btnLoginloading: false });
                this.setState({ warningModalVisible: false });
            });
    }

    handleVerifyOTP(e) {
        e.preventDefault();
        this.setState({ btnLoginloading: true });

        let req = JSON.stringify({
            "otpid": this.state.otpId,
            "otpcode": this.state.otp,
            "noti7day": this.state.consent
        });

        apiClient.post('api/Verify_OTP', req
            , {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {
                if (data.status === 200) {
                    if (data.data.result) {
                        set_cookie(this.state.cookie);
                        this.setState({ btnLoginloading: false });

                        this.setState({ successModalVisible: true, messageSuccess: "Login Successfully" });
                        setTimeout(() => {
                            this.setState({ successModalVisible: false });
                            this.props.history.push("/dashboard");
                        }, 2000);
                    } else {
                        this.setState({ errormessage: 'Invalid OTP Provide!' });
                        if (data.data.isErrorCount) {
                            this.setState({ errormessage: 'You have reached OTP limit, please resend.' });
                        }
                        if (data.data.isExprCode) {
                            this.setState({ errormessage: 'OTP Time out!' });
                        }
                        this.setState({ btnLoginloading: false });
                    }
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ btnLoginloading: false });
            });
    }

    handleWarning() {
        this.setState({
            warningModalVisible: true,
            warningModalMessage: "Do you want to Resend OTP?",
            warningModalCancel: () => { this.setState({ warningModalVisible: false }) },
            warningModalOk: this.handleRequestOTP
        });
    }

    successModal(value) {
        this.setState({ successModalVisible: value });
    }

    handleResendEmail() {
        this.setState({ btnLoginloading: true });
        let Token = this.state.cookie;
        let ExpiredDate = getExpiredDate(Token).toGMTString();
        let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";

        var tokenReplace = SendToken.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        var payload = parseJwt(tokenReplace);
        this.forgotpwd(payload.unique_name, payload.primarysid, payload.email)
    }

    render() {
        const { btnLoginloading, errormessage, showPassword } = this.state;
        return (
            <>
                <div className={`app flex-row align-items-center ${this.state.settingJson.classBackground}`}>
                    <div className="login-container">
                        <Row gutter={[16, 16]}>
                            {this.state.statusTwoFactor ? (
                                <Col xs={24} md={24} lg={9} xl={9}>
                                    <TwoFactor
                                        handleChange={this.handleChange}
                                        _handleKeyPress={this._handleKeyPress}
                                        errormessage={this.state.errormessage}
                                        state={this.state}
                                        setState={this.setState}
                                        history={this.props.history}
                                        handleWarning={this.handleWarning}
                                        handleVerifyOTP={this.handleVerifyOTP}
                                        btnLoginloading={this.state.btnLoginloading}
                                    ></TwoFactor>
                                </Col>
                            ) : this.state.statusDefaultPassword ? (
                                <Col xs={24} md={24} lg={12} xl={12}>
                                    <ChangePassword
                                        state={this.state}
                                        btnLoginloading={this.state.btnLoginloading}
                                        handleResendEmail={this.handleResendEmail}
                                    ></ChangePassword>
                                </Col>
                            ) : (
                                <Col xs={24} md={24} lg={16} xl={8}>
                                    <div className="card-login">
                                        <div className="login-title mb-2">
                                            <div>
                                                <img src={this.state.settingJson.imgpath} alt="logo" style={{ maxWidth: "150px" }} />
                                            </div>
                                            <div className="font-signup">
                                                <div
                                                    style={{
                                                        textDecorationLine: "underline",
                                                        marginRight: "5px",
                                                    }}
                                                    onClick={() => {
                                                        this.setUrl("/register" + this.props.location.search);
                                                    }}
                                                >
                                                    Sign up{" "}
                                                </div>
                                                <Icon type="right" />
                                            </div>
                                        </div>
                                        <div className="login-content">
                                            <div style={{ fontWeight: "bold" }}>Username</div>
                                            <input
                                                type="text"
                                                className="input-login"
                                                ref={(input) => {
                                                    this.inputusername = input;
                                                }}
                                                name="username"
                                                placeholder="Username Or Email"
                                                value={this.state.username}
                                                onChange={this.handleChange}
                                                onKeyPress={this._handleKeyPress}
                                            />
                                            <div style={{ fontWeight: "bold" }}>Password</div>
                                            <div style={{ position: "relative", marginBottom: "20px" }}>
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className="input-login"
                                                    ref={(input) => {
                                                        this.inputpassword = input;
                                                    }}
                                                    name="password"
                                                    onChange={this.handleChange}
                                                    onKeyPress={this._handleKeyPress}
                                                    placeholder="Please enter you password"
                                                />
                                                <Icon
                                                    onClick={this.handleShowPassword}
                                                    type={showPassword ? "eye" : "eye-invisible"}
                                                    className="showpassword"
                                                />
                                            </div>
                                            {errormessage && (
                                                <label style={{ color: "red" }}>{errormessage}</label>
                                            )}
                                            <div style={{ textAlign: "center" }}>
                                                <Button
                                                    block
                                                    loading={btnLoginloading}
                                                    onClick={this.handleLogin}
                                                    className="btn-login"
                                                >
                                                    Log in
                                                </Button>
                                                <div>
                                                    <span>
                                                        By continuing, you agree to Platform’s{" "}
                                                        <span style={{ color: "#EA7326", fontWeight: "bold" }}>
                                                            Terms of Service&nbsp;
                                                        </span>
                                                        <br />
                                                        and acknowledge you’ve read our&nbsp;
                                                        <span style={{ color: "#EA7326", fontWeight: "bold" }}>
                                                            Privacy Policy
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        <ModalSuccess
                            loading={btnLoginloading}
                            successModalVisible={this.state.successModalVisible}
                            successModal={this.successModal}
                            messageSuccess={this.state.messageSuccess}
                        />
                        <ModalBroadCastWarning
                            loading={btnLoginloading}
                            isVisible={this.state.warningModalVisible}
                            message={this.state.warningModalMessage}
                            onOk={this.state.warningModalOk}
                            onCancel={this.state.warningModalCancel}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default Login;
