import React, { useState, useEffect } from "react";
import { Input, Col, Row, Icon, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import {
  recaptchakey,
  CheckUserLogedin,
  set_cookie,
  getExpiredDate,
  smsLoginUrl,
} from "../../../config";
import { useHistory } from "react-router-dom";
import { apiClient, urlClientInfo } from "../../../api";
import queryString from "query-string";
import Form from "./components/Form";
import Otp from "./components/Otp";

export default function Register(props) {
  let history = useHistory();
  const recaptchaRef = React.createRef();
  const [state, setState] = useState({
    showPassword: false,
    showConfirmPassword: false,
    showOtpForm: false,
    showOtpErrorMessage: false,
    otpErrorMessage: "",
    OTPref: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [model, setModel] = useState({
    contactName: "",
    mobileNo: "",
    email: "",
    password: "",
    confirmPassword: "",
    recaptchaToken: null,
    OTPcode: "",
    OTPref: "",
    OTPid: "",
    typeUser: 1,
  });
  const [packageId, setPackageId] = useState("");
  const [clientInfo, setclientInfo] = useState({});

  const [settingJson, setSettingJson] = useState({
    env: {
      title: "Loading...",
      name: "ants",
      imgFolder: "ANTS",
    },
    imgpath: null,
    classBackground: null
  });

  useEffect(() => {
    getClientInfo();
    if (CheckUserLogedin()) {
      history.push("/PackageList");
    }
    if (window.location.search) {
      setPackageId(queryString.parse(window.location.search).pid);
    }

    getSettingJson();
  }, []);

  const getSettingJson = () => {
    apiClient.get('api/function/getappsettings')
      .then((response) => {
        const json = response.data;

        setSettingJson({ ...settingJson, imgpath: `../../../../src/img/${json.env.imgFolder}/Logo_Web.png` })

        switch (json.env.name) {
          case "ANTS":
            setSettingJson({ ...settingJson, classBackground: "login-ants-background" })
            break;
          case "YING":
            setSettingJson({ ...settingJson, classBackground: "login-ants3-background" })
            break;
          case "SMS Up":
            setSettingJson({ ...settingJson, classBackground: "login-ants4-background" })
            break;
          case "Promotech":
            setSettingJson({ ...settingJson, classBackground: "login-promotech-background" })
            break;
        }

      })
      .catch(error => console.error('Error fetching JSON:', error));
  }

  const getClientInfo = () => {
    urlClientInfo
      .get("", {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .then((response) => {
        let req = {
          ipString: response.data.ipString,
          userAgent: response.data.userAgent,
        }
        setclientInfo(req);
      })
      .catch((error) => {
        setclientInfo({
          ipString: "",
          userAgent: "",
        });
      });
  }

  const callGetOTP = () => {
    if (model.mobileNo != "") {
      apiClient
        .post(
          "api/accountregister/getotp",
          { mobileno: model.mobileNo },
          {
            headers: { "Content-type": "application/json; charset=UTF-8" },
          }
        )
        .then((response) => {
          if (response.data.success.message == "Success") {
            setState({ ...state, showOtpForm: true });
            setModel({
              ...model,
              OTPref: response.data.referenceCode,
              OTPid: response.data.otpId,
            });
          } else {
            setErrorMessage(response.data.success.description);
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      setState({ ...state, showOtpForm: false });
    }
  };

  const callRegisterApi = () => {
    let req = {
      name: model.contactName,
      mobileno: model.mobileNo,
      email: model.email,
      username: model.email,
      password: model.password,
      repeatpassword: model.confirmPassword,
      typeUser: model.typeUser,
      pid: packageId,
      client: clientInfo
    };

    apiClient
      .post("api/accountregister/register", req, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .then(async (response) => {
        if (response.data.resultCode == "201") {
          AutoLogin();
        } else {
          setState({ ...state, showOtpForm: false });
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setState({
          ...state,
          showOtpErrorMessage: true,
          otpErrorMessage: error.message,
        });
      });
  };

  const AutoLogin = async () => {
    let req = JSON.stringify({
      "username": model.email,
      "password": model.password,
      "expireMinutes": "60",
      "ip": clientInfo.ipString,
      "device": clientInfo.userAgent,
    });

    apiClient.post('api/Token', req
      , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
      .then(data => {
        var json = data;
        let Token = "__AntiXsrfUerkey=" + encodeURIComponent(json.data);
        let ExpiredDate = getExpiredDate(Token).toGMTString();
        let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";
        set_cookie(SendToken);

        const pid = queryString.parse(window.location.search).pid;
        if (pid) {
          history.push("/RequestTaxInfo" + window.location.search);
        } else {
          history.push("/dashboard");
        }
      })
      .catch(error => {
        setErrorMessage(`Auto login failed: ${error.message}`);
      });
  }

  return (
    <>
      <div className={`app flex-row align-items-center ${settingJson.classBackground}`}>
        <div className="login-container" style={{
          display: "flex",
          justifyContent: "center"
        }}>
          <Col xs={24} md={24} lg={16} xl={8}>
            {!state.showOtpForm ? (
              <Form
                history={history}
                recaptchaRef={recaptchaRef}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                state={state}
                setState={setState}
                model={model}
                setModel={setModel}
                callGetOTP={callGetOTP}
              >
              </Form>
            ) : (
              <Otp
                callRegisterApi={callRegisterApi}
                state={state}
                setState={setState}
                model={model}
                setModel={setModel}
                callGetOTP={callGetOTP}>
              </Otp>
            )}
          </Col>
        </div>
      </div>
    </>
  );
}
