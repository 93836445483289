import React, { useState, useEffect } from "react";
import { Input, Col, Row, Icon, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import {
  recaptchakey,
  CheckUserLogedin,
  set_cookie,
  getExpiredDate,
  smsLoginUrl,
} from "../../../../config";
import { apiClient, urlClientInfo } from "../../../../api";
import queryString from "query-string";

export default function RegisterForm({ history, recaptchaRef, errorMessage, setErrorMessage, state, setState, model, setModel, callGetOTP }) {

  const [loading, setLoading] = useState(false);

  const [settingJson, setSettingJson] = useState({
    name: null
  });

  useEffect(() => {

    getSettingJson();
  }, []);

  const getSettingJson = () => {
    apiClient.get('api/function/getappsettings')
      .then((response) => {
        const json = response.data;

        const file = require(`../../../../../src/img/${json.env.imgFolder}/Logo_Web.png`)
        setSettingJson({ ...settingJson, imgpath: file })

      })
      .catch(error => console.error('Error fetching JSON:', error));
  }

  const handleShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const onChangeRecapha = (token) => {
    setModel({ ...model, recaptchaToken: token });
  };

  const onSubmit = () => {
    setErrorMessage("");
    setLoading(true);

    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue == "") {
      setLoading(false);
      setErrorMessage("Please verify reCAPTCHA");
      return;
    }

    recaptchaRef.current.reset();

    let req = {
      name: model.contactName,
      mobileno: model.mobileNo,
      email: model.email,
      username: model.email,
      password: model.password,
      repeatpassword: model.confirmPassword,
      recaptchatoken: model.recaptchaToken
        ? model.recaptchaToken
        : recaptchaValue,
      typeUser: model.typeUser,
      pid: queryString.parse(window.location.search).pid,
    };

    apiClient
      .post("api/accountregister/validate", req, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .then((response) => {
        if (response.data.validationResult) {
          setLoading(false);
          callGetOTP();
        } else {
          setLoading(false);
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  return (
    <div className="card-login">
      <div className="login-title mb-2">
        <div>
          <img src={settingJson.imgpath} alt="logo" style={{ maxWidth: "150px" }} />
        </div>
        <div className="font-signup" style={{ color: "#112847" }}>
          <div
            style={{ textDecorationLine: "underline", marginRight: "5px", }}
            onClick={() => {
              history.push("/login" + window.location.search);
            }}
          >
            Log in{" "}
          </div>
          <Icon type="right" />
        </div>
      </div>
      <div className="login-content">
        <Row gutter={16}>
          <Col xs={24} md={24} lg={24} xl={12}>
            <div className="text-register" style={{ fontWeight: "bold" }}>
              Contact Name
            </div>
            <Input
              className="input-login"
              placeholder="Ex : John Smith"
              value={model.contactName}
              onChange={(e) =>
                setModel({
                  ...model,
                  contactName: e.target.value.trim(),
                })
              }
            />
          </Col>
          <Col xs={24} md={24} lg={24} xl={12}>
            <div className="text-register" style={{ fontWeight: "bold" }}>Mobile No.</div>
            <Input
              maxLength={10}
              className="input-login"
              placeholder="Ex : 08X XXX XXXX"
              value={model.mobileNo}
              onChange={(e) =>
                setModel({
                  ...model,
                  mobileNo: e.target.value.trim(),
                })
              }
            />
          </Col>
          <Col xs={24} md={24} lg={24} xl={24}>
            <div className="text-register" style={{ fontWeight: "bold" }}>Email</div>
            <Input
              className="input-login"
              placeholder="Ex : email@mail.com"
              value={model.email}
              onChange={(e) =>
                setModel({
                  ...model,
                  email: e.target.value.trim(),
                })
              }
            />
          </Col>
          <Col xs={24} md={24} lg={24} xl={24}>
            <div className="text-register" style={{ fontWeight: "bold" }}>Password</div>
            <div style={{ position: "relative" }}>
              <input
                type={state.showPassword ? "text" : "password"}
                style={{ padding: "0px 0px 0px 10px" }}
                className="input-login"
                value={model.password}
                onChange={(e) =>
                  setModel({
                    ...model,
                    password: e.target.value.trim(),
                  })
                }
                placeholder="Please, set up your “Password”"
              />
              <Icon
                onClick={handleShowPassword}
                type={
                  state.showPassword ? "eye" : "eye-invisible"
                }
                className="showpassword"
              />
            </div>
          </Col>
          <Col xs={24} md={24} lg={24} xl={24}>
            <div className="text-register" style={{ fontWeight: "bold" }}>
              Confirm Password
            </div>
            <div style={{ position: "relative" }}>
              <input
                type={
                  state.showConfirmPassword
                    ? "text"
                    : "password"
                }
                style={{ padding: "0px 0px 0px 10px" }}
                className="input-login"
                value={model.confirmPassword}
                onChange={(e) =>
                  setModel({
                    ...model,
                    confirmPassword: e.target.value.trim(),
                  })
                }
                placeholder="Please, confirm your “Password”"
              />
              <Icon
                onClick={() =>
                  setState({
                    ...state,
                    showConfirmPassword: !state.showConfirmPassword,
                  })
                }
                type={
                  state.showConfirmPassword
                    ? "eye"
                    : "eye-invisible"
                }
                className="showpassword"
              />
            </div>
          </Col>
          <Col xs={24} md={24} lg={16} xl={24}>
            <div className="text-register"
              style={{
                fontSize: "16px",
                color: "#454545",
                lineHeight: "18px",
              }}
            >
              1. The password must be at least 6 characters
              long.
              <br /> 2. It consists of the following characters.
              <br />
              &emsp;- Uppercase letter (A-Z)
              <br />
              &emsp;- Lowercase letter (a-z)
              <br />
              &emsp;- Digit (0-9)- Special character ( = /\ () !
              # $ % ^ & , . + * @ _ - ? ;:)
            </div>
          </Col>
          <Col xs={24} md={24} lg={16} xl={24}>
            {errorMessage && (
              <div style={{ color: "red" }}>{errorMessage}</div>
            )}
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px",
          }}
        >
          <ReCAPTCHA
            stoken={model.recaptchaToken}
            sitekey={recaptchakey}
            onChange={onChangeRecapha}
            ref={recaptchaRef}
          />
        </div>
        <Button
          block
          style={{ backgroundColor: "#19164F", color: "#fff", height: "40px" }}
          onClick={() => onSubmit()}
          loading={loading}
        >
          Sign up
        </Button>
        <div>
          <span>
            By continuing, you agree to Platform’s{" "}
            <span style={{ color: "#EA7326", fontWeight: "bold" }}>
              Terms of Service&nbsp;
            </span>
            <br />
            and acknowledge you’ve read our&nbsp;
            <span style={{ color: "#EA7326", fontWeight: "bold" }}>
              Privacy Policy
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
