import React from "react";
import {
  Card, Col, Row,
} from "reactstrap";
import ClockGray from "../../../../img/clock(gray).svg"
import LetterGray from "../../../../img/letter(gray).svg"
import WriteGray from "../../../../img/write(gray).svg"

export default function CardNormal({ obj, onClickToPage, calculateCredit, profile }) {
  return (
    <Card className="card-package-center">
      <h3 className="card-title">{obj.PackageName}</h3>
      <div style={{ textAlign: "center", margin: "15px" }}>
        <div style={{ fontSize: "25px" }}>
          <span className="plan-title" style={{ fontSize: profile.isNewCustomer == 0 ? "2.5rem" : "3rem" }}>
            {profile.isNewCustomer == 0 ? calculateCredit(obj.Packagetotal_price) : obj.PackagePrice_per_credit}
          </span>
          <span style={{ color: '#ff5e00', fontSize: '35px' }}> {profile.isNewCustomer == 0 ? "Credit" : "THB/SMS"}</span>
        </div>
        <hr className="hr-card" />
        <div style={{ fontSize: "30px" }}>
          <div style={{ fontSize: "40px", color: "#19164F" }}>
            Price
          </div>
          <span className="plan-sub-title">
            {(obj.Packagetotal_price)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
          <span style={{ fontSize: "30px", color: "#19164F" }}>฿</span>
        </div>
      </div>
      <div className="card-content">
        <Row>
          <Col xl="6" style={{ borderRight: '1px solid #19164F' }}>
            <div className="text-desc">
              <img className="icon-card" src={ClockGray} />
              <span style={{ fontSize: "20px" }}>
                Credit period
              </span>
            </div>
            <div className="text-desc">
              <img className="icon-card" src={ClockGray} />
              <span style={{ fontSize: "20px" }}>
                Report period
              </span>
            </div>
            <div className="text-desc">
              <img className="icon-card" src={LetterGray} />
              <span style={{ fontSize: "20px" }}>
                SMS Qty
              </span>
            </div>
            <div className="text-desc">
              <img className="icon-card" src={WriteGray} />
              <span style={{ fontSize: "20px" }}>
                Sender name
              </span>
            </div>
          </Col>
          <Col xl="6">
            <div className="text-desc">
              <span style={{ fontSize: "20px" }}>
                {obj.Package_Packageexpire} Month
              </span>
            </div>
            <div className="text-desc">
              <span style={{ fontSize: "20px" }}>
                {obj.Package_Reportexpire} Month
              </span>
            </div>
            <div className="text-desc">
              <span style={{ fontSize: "20px" }}>
                {obj.Packagetotal_message.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </div>
            <div className="text-desc">
              <span style={{ fontSize: "20px" }}>
                {obj.Package_Sender === 0 ? "Unlimited" : obj.Package_Sender}
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ margin: "0px 30px 20px" }}>
        <Card
          className={"button-orange"}
          onClick={() => {
            let pid = obj.PackageId + "|" + obj.Packagetotal_price;
            onClickToPage(pid, obj.PackageType);
          }}
        >
          <h2 style={{ color: "white", margin: "0px" }}>Buy now</h2>
        </Card>
      </div>
    </Card>
  );
}
