import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, CardGroup, Card, CardBody, InputGroup, Input, Label } from 'reactstrap';
import { Button, Icon } from 'antd';
import { urlgetip } from '../../../api';
import { token, set_cookie, getExpiredDate, Logo_Login, getDefaultPassword, getTwoFactor, parseJwt, CheckTokenExp } from '../../../config';
import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalBroadCastWarning from '../../../components/Ants/Modal/ModalBroadCastWarning';
import { apiClient } from '../../../api';
import { root } from '../../../config';

// const logo = require('../../../img/logo.png')

export class LoginOld extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btnLoginloading: false,
            username: "",
            password: "",
            errormessage: "",
            showPassword: false,
            ip: "",
            clientInfo: "",
            statusDefaultPassword: false,
            statusTwoFactor: false,
            email: "",
            otp: "",
            consent: false,
            token: "",
            otpId: "",
            cookie: "",
            successModalVisible: false,
            warningModalVisible: false,
            warningModalMessage: "",
            warningModalDescription: "",
            messageSuccess: "",
            settingJson: {
                env: {
                    title: "Loading...",
                    name: "ants",
                    imgFolder: "ANTS",
                },
                imgpath: null,
                classBackground: null
            },
        }

        this.getCookie = this.getCookie.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.utilIp = this.utilIp.bind(this);
        this.handleRequestOTP = this.handleRequestOTP.bind(this);
        this.handleVerifyOTP = this.handleVerifyOTP.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.handleWarning = this.handleWarning.bind(this);
        this.handleResendEmail = this.handleResendEmail.bind(this);
        this.register = this.register.bind(this);
        this.getSettingJson = this.getSettingJson.bind(this);
    }

    componentDidMount() {
        this.getCookie("__AntiXsrfUerkey");
        this.utilIp();
        this.getSettingJson();
    }

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                if (name == "__AntiXsrfUerkey=") {
                    this.props.history.push("/dashboard");
                }
            }
        }
        return "";
    }

    handleLogin(e) {
        e.preventDefault();
        this.setState({ btnLoginloading: true });
        this.setState({ errormessage: "" });

        if (this.state.username != "" && this.state.password != "") {
            //let now = new Date();
            //let time = now.getTime();
            //let expireTime = time + 1 * 60 * 1000;
            //now.setTime(expireTime);

            let req = JSON.stringify({
                "username": this.state.username,
                "password": this.state.password,
                // "password": this.state.password,
                "expireMinutes": "60",
                "ip": this.state.ip,
                "device": this.state.clientInfo,
            });

            apiClient.post('api/Token', req
                , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
                .then(data => {
                    var json = data;

                    // if (json.status == 200) {
                    //     console.log('200')
                    //     let Token = "__AntiXsrfUerkey=" + encodeURIComponent(json.token);
                    //     let ExpiredDate = getExpiredDate(Token).toGMTString();
                    //     let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";
                    //     set_cookie(SendToken);
                    //     this.props.history.push("/dashboard");
                    // } else {
                    //     this.setState({ errormessage: json.description });
                    //     this.setState({ btnLoginloading: false });
                    // }

                    let Token = "__AntiXsrfUerkey=" + encodeURIComponent(json.data);
                    let ExpiredDate = getExpiredDate(Token).toGMTString();
                    let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";

                    var tokenReplace = SendToken.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
                    var payload = parseJwt(tokenReplace);

                    this.setState({ token: json.data, cookie: Token });

                    if (payload.defaultPassword === "True") {
                        this.setState({ email: payload.email })
                        this.forgotpwd(payload.unique_name, payload.primarysid, payload.email)
                        this.state.statusDefaultPassword = true
                        this.setState({ btnLoginloading: false });
                    } else if (payload.twofactor === "True") {

                        if (payload.twofactor_7day === "True") {
                            if (payload.twofactor_7day_last_date) {
                                const lastDate = new Date(payload.twofactor_7day_last_date);
                                const currentDate = new Date();
                                lastDate.setHours(0, 0, 0, 0);
                                currentDate.setHours(0, 0, 0, 0);

                                const diffTime = currentDate - lastDate;
                                const diffDays = diffTime / (1000 * 60 * 60 * 24);
                                if (diffDays >= 7) {

                                    this.handleRequestOTP();
                                } else {
                                    set_cookie(SendToken);
                                    this.props.history.push("/dashboard");
                                }
                            }
                        } else {
                            this.handleRequestOTP();
                        }
                        this.setState({ btnLoginloading: false });
                    } else {
                        set_cookie(SendToken);
                        this.props.history.push("/dashboard");
                    }
                })
                .catch(error => {
                    console.log('error', error)
                    this.setState({ errormessage: 'Username or Password is incorrect.' });
                    this.setState({ btnLoginloading: false });
                });
        } else {
            this.setState({ errormessage: "Username or Password is incorrect." });
            setTimeout(() => {
                this.setState({ btnLoginloading: false });
            }, 10);
        }

        if (!this.state.password) {
            this.inputpassword.focus();
        }
        if (!this.state.username) {
            this.inputusername.focus();
        }
    }

    forgotpwd(username, userId, email) {
        let req = JSON.stringify({
            username,
            userId,
            email,
            ip: this.state.ip,
            device: this.state.clientInfo
        });

        apiClient.post('api/usersmanage/forgetpassword', req
            , {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {

                var json = data.data;
                // if (json == true) {

                //     this.setState({
                //         successModalVisible: true,
                //         messageSuccess: "Email forgot password sent successfully"
                //     });
                //     setTimeout(() => {
                //         this.setState({ successModalVisible: false });
                //     }, 3000);
                // } else {
                //     this.setState({
                //         errorModalVisible: true,
                //         messageError: "Email forgot password sent fail",
                //     });
                // }
                this.setState({ btnLoginloading: false });
            })
            .catch(error => {
                this.setState({ btnLoginloading: false });
            });
    }

    handleResendEmail() {
        this.setState({ btnLoginloading: true });
        let Token = this.state.cookie;
        let ExpiredDate = getExpiredDate(Token).toGMTString();
        let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";

        var tokenReplace = SendToken.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        var payload = parseJwt(tokenReplace);
        this.forgotpwd(payload.unique_name, payload.primarysid, payload.email)
    }

    handleWarning() {
        this.setState({
            warningModalVisible: true,
            warningModalMessage: "Do you want to Resend OTP?",
            warningModalCancel: () => { this.setState({ warningModalVisible: false }) },
            warningModalOk: this.handleRequestOTP
        });
    }

    handleRequestOTP() {
        this.setState({ btnLoginloading: true });
        let req = JSON.stringify({
        });
        this.state.statusTwoFactor = true

        apiClient.post('api/RequestOTP', req
            , {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {

                if (data.status === 200) {
                    this.setState({ otpId: data.data.data });
                    this.setState({ btnLoginloading: false });
                    this.setState({ warningModalVisible: false });
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ btnLoginloading: false });
                this.setState({ warningModalVisible: false });
            });
    }

    handleVerifyOTP(e) {
        e.preventDefault();
        this.setState({ btnLoginloading: true });
        let req = JSON.stringify({
            "otpid": this.state.otpId,
            "otpcode": this.state.otp,
            "noti7day": this.state.consent
        });

        apiClient.post('api/Verify_OTP', req
            , {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {
                if (data.status === 200) {
                    if (data.data.result) {
                        set_cookie(this.state.cookie);
                        this.setState({ btnLoginloading: false });

                        this.setState({ successModalVisible: true, messageSuccess: "Login Successfully" });
                        setTimeout(() => {
                            this.setState({ successModalVisible: false });
                            this.props.history.push("/dashboard");
                        }, 2000);
                    } else {
                        this.setState({ errormessage: 'Invalid OTP Provide!' });
                        if (data.data.isErrorCount) {
                            this.setState({ errormessage: 'You have reached OTP limit, please resend.' });
                        }
                        if (data.data.isExprCode) {
                            this.setState({ errormessage: 'OTP Time out!' });
                        }
                        this.setState({ btnLoginloading: false });
                    }
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ btnLoginloading: false });
            });
    }

    handleChange(e) {
        const target = e.target;

        if (target.type === 'checkbox') {
            this.setState({ [target.name]: target.checked });
        } else {
            this.setState({ [target.name]: target.value });
        }
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleLogin(e);
        }
    }

    handleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });

    }

    utilIp() {
        urlgetip.get(''
            , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
            .then(data => {
                var json = data.data;
                this.setState({ ip: json });
            })
            .catch(error => {
                this.setState({ ip: "" });
            });
    }

    successModal(value) {
        this.setState({ action: "", successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        this.setState(state => {
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                }, secondsToGo * 1000);
            }
        });
    }

    register() {
        this.props.history.push("/register" + window.location.search);
    }

    getSettingJson() {
        apiClient.get('api/function/getappsettings')
            .then((response) => {
                const json = response.data;

                this.setState({ settingJson: { ...this.state.settingJson, env: json.env } })

                const file = require(`../../../../src/img/${json.env.imgFolder}/Logo_Web.png`)
                this.setState({ settingJson: { ...this.state.settingJson, imgpath: file } })

                switch (json.env.name) {
                    case "ANTS":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-ants-background" } })
                        break;
                    case "YING":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-ants3-background" } })
                        break;
                    case "SMS Up":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-ants4-background" } })
                        break;
                    case "Promotech":
                        this.setState({ settingJson: { ...this.state.settingJson, classBackground: "login-promotech-background" } })
                        break;
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    render() {
        const { btnLoginloading, errormessage, showPassword, successModalVisible, warningModalVisible, warningModalMessage, warningModalOk, warningModalCancel } = this.state;

        return (
            <div className={`app flex-row align-items-center ${this.state.settingJson.classBackground}`}>
                <Container className="container-login">
                    <img src={this.state.settingJson.imgpath} className="login-logo" />
                    {/* //  Logo  บน tab Login   */}
                    <Row className="justify-content-left">
                        <Col md="6">
                            <CardGroup>
                                <Card className="p-4 login-card">
                                    <CardBody>
                                        {this.state.statusTwoFactor ? (
                                            <div>
                                                <h1 className="login-titel2">Two-Factor Authentication</h1>
                                                <p><span className="fa fa-envelope"></span> &nbsp;&nbsp; Please enter the 6-digit one-time password (OTP) sent to your email address within 5 minutes. You have 3 attempts to enter the correct code.</p>
                                                <h5>Authentication Code</h5>
                                                <p />
                                                <InputGroup className="">
                                                    <input type="text" className="input-login"
                                                        name="otp" placeholder="Enter OTP Code" onChange={this.handleChange} onKeyPress={this._handleKeyPress} />
                                                </InputGroup>
                                                {errormessage ? <label htmlFor={errormessage} style={{ color: "red" }}>{errormessage}</label> : null}
                                                {/* <Col xs="12">
                                                <div style={{ float: 'right' }}>
                                                    <Input className="form-check-input" type="checkbox" id="consent" name="consent" checked={this.state.consent} onChange={this.handleChange} />
                                                    <Label check className="form-check-label" htmlFor="consent">Remember this device for 7 days</Label>
                                                </div>
                                            </Col>
                                            <Row>
                                                <Col xs="6">
                                                    <Button className='' type="primary" style={{ float: 'left' }} onClick={this.handleWarning}>
                                                        Resend Code
                                                    </Button>
                                                </Col>
                                                <Col xs="6">
                                                    <Button type="primary" style={{ marginRight: 8, float: 'right' }} loading={btnLoginloading} onClick={this.handleVerifyOTP}>
                                                        Verify
                                                    </Button>
                                                </Col>
                                            </Row> */}
                                                <Col xs="12">
                                                    <div className="checkbox-container" style={{ marginLeft: "10px", marginBottom: "20px" }}>
                                                        <Input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="consent"
                                                            name="consent"
                                                            checked={this.state.consent}
                                                            onChange={this.handleChange}
                                                        />
                                                        <Label check className="form-check-label" htmlFor="consent">
                                                            Remember this device for 7 days
                                                        </Label>
                                                    </div>
                                                </Col>
                                                <Row className="button-row">
                                                    <Button
                                                        className="resend-button"
                                                        type="success"
                                                        onClick={this.handleWarning}
                                                        style={{ marginRight: "10px" }}
                                                    >
                                                        Resend Code
                                                    </Button>
                                                    <Button
                                                        className="verify-button"
                                                        type="primary"
                                                        loading={btnLoginloading}
                                                        onClick={this.handleVerifyOTP}
                                                    >
                                                        Verify
                                                    </Button>
                                                </Row>
                                            </div>
                                        ) : this.state.statusDefaultPassword ? (
                                            <>
                                                <div className='login-first-card'>
                                                    <h1 className="login-titel2">Please Change Your Password After First Login</h1>
                                                    <hr />
                                                    <p className="login2-body">
                                                        &nbsp; &nbsp; &nbsp; Dear User,
                                                        For the security of your account, please change your password immediately after logging in for the first time. An email with instructions on how to change your password has been sent to {this.state.email}.
                                                        Thankyou for your cooperation.
                                                        Best regards,
                                                        The Team
                                                    </p>
                                                    <p>
                                                        Thank you for your cooperation.
                                                    </p>
                                                    <Row>
                                                        <Col xs="6">
                                                            <Button className='login2-botton' type="success" style={{ float: 'left' }} loading={btnLoginloading} onClick={this.handleResendEmail}>
                                                                Resend email
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <p className='login3-body'>
                                                        Please check the correctness of your email and wait, the system is automatically sending an email to you.
                                                        If it takes more than 5 minutes, please press the Resend button to send an email again.
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                <h1 className="login-titel">Login to your account</h1>
                                                <h5>Username</h5>
                                                <p />
                                                <InputGroup className="mb-3">
                                                    <input type="text" className="input-login"
                                                        ref={(input) => { this.inputusername = input; }}
                                                        name="username" placeholder="Please enter your username" onChange={this.handleChange} onKeyPress={this._handleKeyPress} />
                                                </InputGroup>
                                                <h5>Password</h5>
                                                <p />
                                                <InputGroup className="mb-3">
                                                    <input type={showPassword ? "text" : "password"} className="input-login"
                                                        ref={(input) => { this.inputpassword = input; }}
                                                        name="password" onChange={this.handleChange} onKeyPress={this._handleKeyPress} placeholder="Please enter your password" />
                                                    <Icon onClick={this.handleShowPassword} type={showPassword ? "eye" : "eye-invisible"} className="showpassword" />
                                                </InputGroup>
                                                <Row>
                                                    <Col xs="6">
                                                        {/* <Button type="primary" style={{ marginRight: 8, float: 'left' }} onClick={this.register}>
                                                            Register
                                                        </Button> */}
                                                    </Col>
                                                    <Col xs="6">
                                                        <Button type="primary" style={{ marginRight: 8, float: 'right' }} loading={btnLoginloading} onClick={this.handleLogin}>
                                                            Login
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" style={{ textAlign: "right" }}>
                                                        <label htmlFor={errormessage} style={{ color: "red" }}>{errormessage}</label>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                    <ModalSuccess
                        loading={btnLoginloading}
                        successModalVisible={successModalVisible}
                        successModal={this.successModal}
                        messageSuccess={this.state.messageSuccess}
                    />
                    <ModalBroadCastWarning
                        loading={btnLoginloading}
                        isVisible={warningModalVisible}
                        message={warningModalMessage}
                        onOk={warningModalOk}
                        onCancel={warningModalCancel}
                    />
                </Container>
            </div>
        );
    }
}

export default LoginOld;
