
export const TransectionStatus = {
    FAIL: { code: 0, name: 'Failed' },
    EXPIRED: { code: 1, name: 'Expired' },
    PENDING: { code: 2, name: 'Pending' },
    SUCCESS: { code: 3, name: 'Successful' },
    REVERSED: { code: 4, name: 'Reversed' }
};

export const TransectionErrorType = {
    ERROR: 999, // error every thing
    SUCCESS: 300,
    NOT_FOUND_ORDER: 998,
    ORDER_FAILED: 601,
    ORDER_REVERSED: 602,
    ORDER_EXPIRED: 603,
    OMISE_CHARGE_NOT_SUCCESS: 701, // omise charge status !== 'successful' or 'pending'
    ORDER_PENDING: 702,
    UPDATE_STATUS_ORDER_FAIL: 801,
    CREATE_RECEIPT: 803,
    ADD_CREDIT_LOG: 804,
    INNTERNAL_ERROR: 805, // service save or update fail
    ERR_UPDATE_ACCPRICE: 809
};

export const TaxType = {
    INDIVIDUAL: 1,
    CORPORATE: 2
};