import React, { useState, useEffect } from "react";
import {
  Card, Col, Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { apiClient } from "../../../api";
import { token, CheckTokenExp } from '../../../config';
import CardDemo from "./CardPackage/CardDemo";
import CardNormal from "./CardPackage/CardNormal";
import CardRecommend from "./CardPackage/CardRecommend";
import CardCustom from "./CardPackage/CardCustom";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css';
import { Navigation, Pagination, Mousewheel } from "swiper";

export default function PackageList() {
  let history = useHistory();

  useEffect(() => {
    getProfile();
    fetchData();
  }, []);

  const [data, setData] = useState([]);
  const [profile, setProfile] = useState({});

  const onClickToPage = (id) => {
    const encodedData = btoa(id); // encode a string
    history.push("RequestTaxInfo?pid=" + encodedData);
  };

  const fetchData = async () => {
    let req = null;

    apiClient.post("api/ecom/getPackagedata", req, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        setData(response.data.PackageList);
      })
      .catch((error) => { });
  };

  const formatCurrency = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getProfile = () => {
    if (!CheckTokenExp()) {
      const req = null

      apiClient.post('api/profile/GetProfile', req
        , {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
          }
        })
        .then(data => {
          var json = data.data[0];

          if (json) {
            setProfile(json);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      window.location.href = "logout";
    }
  }

  const calculateCredit = (price) => {
    return Math.ceil(price / profile.creditRate).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const ClearCookie = () => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time;
    now.setTime(expireTime);
    document.cookie =
      "__AntiXsrfUerkey=" + "" + ";expires=" + now.toGMTString() + ";path=/";
    // this.props.history.push("/PackageList");
    localStorage.clear();
  };

  return (
    <div className="app" style={{ minHeight: 0 }}>
      <div className="package">
        <div>
          <p
            style={{
              textAlign: "center",
              margin: "20px 0px",
              fontSize: "3rem",
              fontWeight: "bold",
            }}
          >
            How many SMS do you plan to send?
          </p>
        </div>
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Swiper
            spaceBetween={25}
            navigation={true}
            mousewheel={true}
            pagination={false}
            grabCursor={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 2,
              },
              1366: {
                slidesPerView: 3,
              },
              1920: {
                slidesPerView: 5,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel]}
            className="mySwiper"
          >
            {data && data.length > 0 &&
              data.map((obj, index) => {
                if (obj.PackageType === 2) {
                  return <SwiperSlide><CardDemo key={index + 1} obj={obj} ClearCookie={ClearCookie} formatCurrency={formatCurrency} onClickToPage={onClickToPage} /></SwiperSlide>
                } else if (obj.PackageType === 1 && obj.Package_Recommend) {
                  return <SwiperSlide><CardRecommend key={index + 1} obj={obj} onClickToPage={onClickToPage} calculateCredit={calculateCredit} profile={profile} /></SwiperSlide>
                } else if (obj.PackageType === 1 && !obj.Package_Recommend) {
                  return <SwiperSlide><CardNormal key={index + 1} obj={obj} onClickToPage={onClickToPage} calculateCredit={calculateCredit} profile={profile} /></SwiperSlide>
                }
              })}
            {profile.isNewCustomer !== 1 &&
              <SwiperSlide><CardCustom key={data.length + 1} formatCurrency={formatCurrency} onClickToPage={onClickToPage} history={history} /></SwiperSlide>
            }
          </Swiper>
        </div>
      </div>
    </div>
  );
}
