import React, { Component } from 'react';


import { Route, Router, Switch } from 'react-router';
import 'antd/dist/antd.css';

// Styles
// Import Flag Icons Set
//import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.scss';
// Temp fix for reactstrap
import './scss/core/_dropdown-menu-right.scss';

import { Helmet } from "react-helmet";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Login } from './views/Pages/Login';
import LoginOld from './views/Pages/Login/LoginOld';
import { Logout } from './views/Pages/Logout/Logout';
import { Console } from './Console';
import Full from './containers/Full/';
import { Title } from "./config";
import Register from './views/Pages/Register';
import PackageListSale from './views/Ecommerce/PackageListSale'
import { apiClient } from "./api";
export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {
      settingJson: {
        env: {
          title: "Loading...",
          name: "ants",
          imgFolder: "ANTS",
        },
      },
      imgpath: null
    };
  }
  componentDidMount() {
    apiClient.get('api/function/getappsettings')
      .then((response) => {
        const json = response.data;

        this.setState({ settingJson: json })

        const file = require(`../src/img/${json.env.imgFolder}/Title.png`)
        this.setState({ imgpath: file })
      })
      .catch(error => console.error('Error fetching JSON:', error));
  }

  render() {

    const { title, imgFolder, name, signUpVersion } = this.state.settingJson.env;

    return (
      <>
        <Helmet>
          <title data-react-helmet="true">{title}</title>
          <link rel="shortcut icon" href={this.state.imgpath} />
        </Helmet>
        <Layout>
          <Switch>
            <Route path="/packageSale" component={PackageListSale} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/counter' component={Counter} />
            <Route exact path='/fetch-data' component={FetchData} />
            <Route exact path='/login' component={signUpVersion ? Login : LoginOld} />
            <Route exact path='/logout' component={Logout} />
            <Route path='/' component={Full} />

          </Switch>
        </Layout>
      </>
    );
  }
}
