﻿/* eslint-disable */
import React, { Component } from "react";
import {
  PageHeader,
  Button,
  Descriptions,
  Select,
  Row,
  Col,
  DatePicker,
  Icon,
  Form,
  Input,
  Card,
  Tooltip,
  Progress,
  Table,
  Divider,
  Tag,
  Modal,
  Upload,
  Drawer,
  Spin,
  Collapse,
  Breadcrumb,
} from "antd";
import moment from "moment";
import "moment-timezone";
import NumericLabel from "react-pretty-numbers";
import {
  token,
  getUrlVars,
  formatNumber,
  CheckTokenExp,
} from "../../../config";
import Card2_Extra from "../../../components/Ants/Card/Card2_Extra";
import TableRecipients from "../../../components/Ants/Table/TableRecipients";
import { MentionsInput, Mention } from "react-mentions";
import MentionStyle_preview from "../../../components/Ants/Mentions/Style/MentionStyle_preview";
import ModalSuccess from "../../../components/Ants/Modal/ModalSuccess";
import ModalError from "../../../components/Ants/Modal/ModalError";
import ModalErrorWarning from "../../../components/Ants/Modal/ModalErrorWaring";
import ModalWarning from "../../../components/Ants/Modal/ModalWarning";
import ModalInput from "../../../components/Ants/Modal/ModalInput";
import ModalCreateReport from "../../../components/Ants/Modal/ModalCreateReport";
import TableCredit from "../../../components/Ants/Table/TableCredit";
import TableAnalyze from "../../../components/Ants/Table/TableAnalyze";
import {
  textforceStop,
  textResend,
} from "../../../components/Ants/TooltipText/Text";
import { apiClient } from "../../../api";
import { root } from "../../../config";
import ModalServerError from "../../../components/Ants/Modal/ModalServerError";
moment.tz.setDefault("Asia/Bangkok");

const { Panel } = Collapse;
const { Option } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { confirm } = Modal;
const dateFormat = "DD/MM/YYYY";
const { Search } = Input;

export default class Detail extends Component {
  constructor(props) {
    super(props);

    this.onClickPreview = this.onClickPreview.bind(this);
    this.warningModal = this.warningModal.bind(this);
    this.inputModal = this.inputModal.bind(this);
    this.createReportModal = this.createReportModal.bind(this);
    this.successModal = this.successModal.bind(this);
    this.modalcountDown = this.modalcountDown.bind(this);
    this.setStateByProps = this.setStateByProps.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.confirmDownloadFile = this.confirmDownloadFile.bind(this);

    this.getFileCampaign = this.getFileCampaign.bind(this);
    this.getStatusCampaign = this.getStatusCampaign.bind(this);
    this.statusCampaignByid = this.statusCampaignByid.bind(this);
    this.ResendFail = this.ResendFail.bind(this);
    this.AnalyzeFunc = this.AnalyzeFunc.bind(this);
    this.analyze_onClose = this.analyze_onClose.bind(this);
    this.analyze_showDrawer = this.analyze_showDrawer.bind(this);
    this.CancelCampaign = this.CancelCampaign.bind(this);
    this.createreport = this.createreport.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleCreateTaemplate = this.handleCreateTaemplate.bind(this);
    this.getData = this.getData.bind(this);
    this.getCampaignDetail = this.getCampaignDetail.bind(this);

    this.statusCampaignColor = this.statusCampaignColor.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.errorWarningModal = this.errorWarningModal.bind(this);

    this.state = {
      variablePreview: false,
      analyze_visible: false,
      loading: false,
      warningModalVisible: false,
      successModalVisible: false,
      inputModalVisible: false,
      btnsend_loading: false,
      message: "",
      messageSuccess: "",
      screenwidth: 0,
      analyzeTable: [],
      data: [],
      total_recipients: [],
      total_recipients_analyze: [],
      dataScheduling: [],
      variable: [
        "firstName",
        "lastName",
        "address",
        "city",
        "country",
        "gender",
        "birthDate",
      ],
      columnsScheduling: [
        {
          dataIndex: "title",
          key: "title",
          render: (text) => (
            <div
              style={{ fontWeight: "bold", fontSize: 18, padding: "12px 1px" }}
            >
              {text}
            </div>
          ),
        },
        {
          dataIndex: "value",
          key: "value",
          render: (text) => (
            <div style={{ fontSize: 18, padding: "12px 1px" }}>{text}</div>
          ),
        },
      ],
      columnsFile: [
        {
          title: "Upload File Name",
          dataIndex: "name",
          key: "name",
          width: "40%",
        },
        {
          title: "Submitted Dated",
          dataIndex: "date",
          key: "date",
          width: "20%",
          render: (text) =>
            moment(text, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm"),
        },
        {
          title: "Submitted By (users)",
          dataIndex: "user",
          key: "user",
          width: "30%",
        },
      ],
      dataFile: [],
      templateName: "",
      popUpTitel: "",
      createReportModalVisible: false,
      listStatus: [],
      SUM_RECIPTENTS: "",
      fileoid: undefined,
      filename: undefined,
      loadingAll: true,
      campaignDetail: [],
      errorModalVisible: false,
      errorWarningModalVisible: false,
      messageError: "",
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.getStatusCampaign();
    this.getFileCampaign();
    this.getData();
    this.getCampaignDetail();

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
    sessionStorage.clear();
  }

  getData() {
    if (!CheckTokenExp()) {
      this.setState({ loadingAll: true });

      let req = JSON.stringify({
        _campId: getUrlVars()["id"],
      });
      apiClient
        .post("api/campaign/DeliveredDetail_new/", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data[0];
          if (json.draft == true) {
            window.location.href =
              "communication/edit?id=" +
              getUrlVars()["id"] +
              "&type=" +
              (json.campType == 2 ? "broadcast" : "broadcasttemplate");
          }

          this.setState(
            {
              data: json ? json : {},
            },
            () => {
              this.updateWindowDimensions();
            }
          );

          this.setState((state) => {
            return {
              total_recipients: [
                {
                  key: "1",
                  status: "Unique",
                  recipients: state.data.uniqueAmount,
                  id: getUrlVars()["id"],
                },
                {
                  key: "2",
                  status: "Duplicate",
                  recipients: state.data.duplicate,
                  id: getUrlVars()["id"],
                },
                {
                  key: "3",
                  status: "Blacklist",
                  recipients: state.data.blacklist,
                  id: getUrlVars()["id"],
                },
                {
                  key: "4",
                  status: "Invalid",
                  recipients: state.data.invalid,
                  id: getUrlVars()["id"],
                },
              ],
              dataScheduling: [
                {
                  key: "1",
                  title: "Started At",
                  value: state.data.sentAt
                    ? moment(state.data.sentAt, "YYYY/MM/DD HH:mm").format(
                      "DD/MM/YYYY HH:mm"
                    )
                    : "-",
                },
                {
                  key: "2",
                  title: "Scheduled",
                  value: state.data.scheduledDate
                    ? moment(
                      state.data.scheduledDate,
                      "YYYY/MM/DD HH:mm"
                    ).format("DD/MM/YYYY HH:mm")
                    : "Immediated",
                },
                {
                  key: "3",
                  title: "Timezone",
                  value: "Thailand / Bangkok (ICT)",
                },
                {
                  key: "4",
                  title: "Sender Name",
                  value: state.data.senderName,
                },
              ]
              // data: {
              //   contentCamp: state.data.smsContent
              // }
            };
          });

          this.setState({ loadingAll: false });
        })
        .catch((error) => {
          this.setState({ loadingAll: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  getCampaignDetail() {
    if (!CheckTokenExp()) {
      this.setState({ loadingAll: true });

      let req = JSON.stringify({
        _campId: getUrlVars()["id"],
      });
      apiClient
        .post("api/campaign/GetCampaignDetail/", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data[0];
          this.setState({
            loadingAll: false,
            campaignDetail: json ? json : {},
          });
        })
        .catch((error) => {
          this.setState({ loadingAll: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  onClickPreview(value) {
    if (value) {
      setTimeout(() => {
        let modalcontent = document.querySelectorAll(".ant-modal-content");
        for (var i = 0; i < modalcontent.length; i++) {
          modalcontent[i].className += " ant-modal-content-hide";
        }
      }, 10);
    } else {
      setTimeout(() => {
        let modalcontent = document.querySelectorAll(".ant-modal-content-hide");
        for (var i = 0; i < modalcontent.length; i++) {
          modalcontent[i].className = " ant-modal-content";
        }
      }, 10);
    }

    this.setState({
      variablePreview: value,
    });
  }

  onClickSeeLog() {
    sessionStorage.setItem("campaignId", getUrlVars()["id"]);
    sessionStorage.setItem("campaignName", this.state.data.campaignName);
    sessionStorage.setItem("startDate", this.state.data.sentAt);
    window.open(`/report/logs`);
  }

  warningModal(value, message, messageSuccess) {
    this.setState({ warningModalVisible: value, message, messageSuccess });
  }

  inputModal(value, message, messageSuccess) {
    this.setState({ inputModalVisible: value, message, messageSuccess });
  }

  createReportModal(value, message, messageSuccess) {
    this.setState({ createReportModalVisible: value, message, messageSuccess });
  }

  successModal(value) {
    if (this.state.action == "download") {
      this.downloadFile(this.state.fileoid, this.state.filename);
      this.setState({ action: "" });
      return false;
    }
    if (this.state.action == "analyze") {
      value = false;
      this.analyze_showDrawer();
      this.setState({ action: "" });
    }
    if (this.state.action == "resend") {
      this.ResendFail();
      this.setState({ action: "" });
    }
    if (this.state.action == "cancel") {
      this.CancelCampaign();
      this.setState({ action: "" });
    }
    if (this.state.action == "createreport") {
      this.createreport();
      this.setState({ action: "" });
    }
    if (this.state.action == "copy") {
      this.handleDuplicate();
      this.setState({ action: "" });
    }

    this.setState({ successModalVisible: value });

    if (this.state.action == "createtemplate") {
      this.handleCreateTaemplate();
      this.setState({ action: "" });
    }
  }

  modalcountDown() {
    let secondsToGo = 2;
    this.setState((state) => {
      if (state.successModalVisible) {
        setTimeout(() => {
          this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);
      }
      if (state.errorWarningModalVisible) {
        setTimeout(() => {
          this.setState({ errorWarningModalVisible: false });
        }, secondsToGo * 1000);
      }
    });
  }

  setStateByProps(parameters, value) {
    value ? value : "";
    this.setState({ [parameters]: value });
  }

  updateWindowDimensions() {
    setTimeout(() => {
      var root = document.getElementById("root");
      if (root) {
        var e = document.getElementById("main");
        if (e) {
          var scroll = root.offsetHeight > window.innerHeight;
          this.setState({
            screenwidth: scroll ? e.offsetWidth - 132 : e.offsetWidth - 132,
          });
        }
      }
    }, 500);
  }

  downloadFile(item, name) {
    if (!CheckTokenExp()) {
      apiClient
        .get("api/file/download/" + item, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          responseType: "blob",
        })
        .then((data) => {
          var json = data.data;
          const url = window.URL.createObjectURL(new Blob([json]));
          const link = document.createElement("a");
          link.href = url;
          link.download = name;
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  getFileCampaign() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });
      apiClient
        .get("api/file/getFileByCampaign/" + getUrlVars()["id"], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;
          this.setState({
            dataFile: json ? json : [],
          });
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  getStatusCampaign() {
    if (!CheckTokenExp()) {
      apiClient
        .get("api/Campaign/GetStatusList", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          if (data.status === 200 && data.data) {
            this.setState({ listStatus: data.data });
          } else {
            this.setState({ modalVisible: true });
            this.setState({ listStatus: [] });
          }
        })
        .catch((error) => {
          this.setState({ modalVisible: true });
          this.setState({ listStatus: [] });
        });
    } else {
      window.location.href = "logout";
    }
  }

  statusCampaignByid(value) {
    const { listStatus } = this.state;

    let res = listStatus.filter((i) => i.ID == value);

    return res.length > 0 ? res[0].TEXT : "IN PROGRESS";
  }

  ResendFail() {
    if (!CheckTokenExp()) {
      this.setState({ launch: true, loading: true });
      var CAMP_ID = getUrlVars()["id"];
      if (CAMP_ID > 0) {
        let req = JSON.stringify({
          id: CAMP_ID,
        });

        apiClient
          .post("api/Campaign/ResendList", req, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          })
          .then((data) => {
            var json = data.data;

            this.analyze_onClose();

            this.AnalyzeFunc();

            this.resendModal(false);

            this.setState({ resendlist: [] });
            this.setState({ loading: false });
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      }
    } else {
      window.location.href = "logout";
    }
  }

  AnalyzeFunc() {
    if (!CheckTokenExp()) {
      this.setState({ btnsend_loading: true });

      let req = JSON.stringify({
        id: getUrlVars()["id"],
      });

      apiClient
        .post("api/Campaign/AnalyzeResend", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;

          var total_credit = [
            {
              key: "1",
              name: "Shortest",
              characters: json.SUM_SHORT_MSG,
            },
            {
              key: "2",
              name: "Longest",
              characters: json.SUM_LONG_MSG,
            },
          ];

          var total_recipients_analyze = [
            {
              key: "1",
              status: "Unique",
              recipients: json.SUM_RECIPTENTS,
            },
            {
              key: "2",
              status: "Duplicate",
              recipients: json.SUM_DUPICATE,
            },
            {
              key: "3",
              status: "Blacklist",
              recipients: json.SUM_BLACKLIST,
            },
            {
              key: "4",
              status: "Invalid",
              recipients: json.SUM_INVALID_NUMBER,
            },
          ];

          var analyzeTable = [
            {
              key: "1",
              name: this.state.data.senderName,
              schedule: moment().format("DD/MM/YYYY HH:mm"),
            },
          ];

          this.setState({
            total_credit: total_credit,
            sum_credit: formatNumber(json.SUM_CREDIT),
            SUM_RECIPTENTS: formatNumber(json.SUM_RECIPTENTS),
            total_recipients_analyze: total_recipients_analyze,
            analyzeTable: analyzeTable,
            sum_message: json.SUM_MESSAGE,

            Analyz: false,
            show_summary: true,
            btnsend_loading: false,
          });
        })
        .catch((error) => {
          this.setState({ btnsend_loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  analyze_onClose() {
    this.setState({
      analyze_visible: false,
    });
  }

  analyze_showDrawer() {
    this.AnalyzeFunc();
    this.setState({ analyze_visible: true });
  }

  CancelCampaign() {
    if (!CheckTokenExp()) {
      var id = getUrlVars()["id"];
      apiClient
        .get("api/Campaign/CancelCampiagn/" + id, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;
          this.setState({
            dataSource: [],
            SMS_TO: "",
          });
          this.getData();
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ modalVisible: true });
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  errorModal(value) {
    this.setState({ errorModalVisible: value });
  }

  errorWarningModal(value) {
    this.setState({ errorWarningModalVisible: value });
  }

  createreport() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });
      let date = moment().format("YYYYMMDDHHmmss");
      var nameReport = this.state.data.campaignName.trim() + date;


      let startDate =
        this.state.data.scheduledDate == null
          ? moment(this.state.campaignDetail.CAMP_CREATE_DATE)
          : moment(this.state.data.scheduledDate);
      let endDate = moment(startDate).add(8, "hours");


      // start cream
      let req = JSON.stringify({
        rpt_type: 1, //Detailed Report
        rpt_name: nameReport, //report name
        onetimereport_type: 8, //Custom

        filter_CAMP_ID: [this.state.campaignDetail.CAMP_ID], //CAMP_ID
        filter_SMS_STARTSCHEDULE: startDate.format("YYYY-MM-DD HH:mm:00"),
        filter_SMS_ENDSCHEDULE: endDate.format("YYYY-MM-DD HH:mm:59"),
        filter_STATUS_CODE: [],
        filter_ACCOUNTS_ID: [],
        filter_USR_ID: [],
        filter_SEND_NAME: [],
        filter_SMS_STARTDISPLAYSCHEDULE: startDate.format("DD/MM/YYYY HH:mm"),
        filter_SMS_ENDDISPLAYSCHEDULE: endDate.format("DD/MM/YYYY HH:mm"),
        filter_OTP: [],

        alert_flag: false,
        alert_email: [],

        ftp_flag: false,
        ftp_export_type: 0,
        ftp_protocal: 1,
        ftp_host: "",
        ftp_port: "",
        ftp_username: "",
        ftp_password: "",
        ftp_path: "/",

        recuring_flag: false,
        recuring_type: 0,
        recuring_reportrange: "",
        recuring_value: 0,
      });

      apiClient
        .post("api/report/addreportnew", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;
          this.setState({ loading: false });
          if (json.Code !== "200") {
            this.setState({
              errorModalVisible: true,
              messageError: json.Description,
            });
          } else {
            this.setState({ action: "" });
            this.setState({ successModalVisible: true });
            this.modalcountDown();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({ errorModalVisible: true, messageError: error });
        });
    } else {
      window.location.href = "logout";
    }
  }

  handleDuplicate() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });
      apiClient
        .get("api/Campaign/DuplicateCapaign/" + getUrlVars()["id"], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;

          window.location.href =
            "communication/edit?id=" +
            json +
            "&type=" +
            (this.state.data.campType == 2 ? "broadcast" : "broadcasttemplate");

          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  handleCreateTaemplate() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });
      const { data } = this.state;
      const formData = new FormData();

      if (!this.state.templateName || !data.campContent || !data.senderName) {
        this.setState({ successModalVisible: false });
        this.setState({
          errorWarningModalVisible: true,
          messageError: "This campaign cannot create a template.",
        });
        // this.warningModal(true, "This campaign cannot create a template.", "Done.")
        this.setState({ loading: false });
      } else {
        let req = JSON.stringify({
          key: 0,
          templatename: this.state.templateName,
          contents: data.campContent,
          sender: data.senderName,
          shortedurl: data.shortenCamp,
        });

        formData.append("req", req);

        apiClient
          .post("api/template/addupdate", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          })
          .then((data) => {
            // var json = data.data.data[0];
            this.setState({ successModalVisible: true });
            this.setState({ loading: false });
          })
          .catch((error) => {
            this.setState({ modalVisible: true });
            this.setState({ loading: false });
          });
      }

    } else {
      window.location.href = "logout";
    }
  }

  statusCampaignColor(value) {
    const list = [
      { ID: 0, COLCOR: "tag-draft" },
      { ID: 1, COLCOR: "tag-inProgres" },
      { ID: 4, COLCOR: "tag-schedu" },
      { ID: 3, COLCOR: "tag-complete" },
      { ID: 7, COLCOR: "tag-pending" },
      { ID: 8, COLCOR: "tag-cancel" },
      { ID: 9, COLCOR: "tag-error" },
      { ID: 10, COLCOR: "tag-waiting" },
    ];

    let res = list.filter((i) => i.ID == value);

    return res[0].COLCOR;
  }

  confirmDownloadFile(fileoid, name) {
    this.setState({
      fileoid: fileoid,
      filename: name,
    });
    this.warningModal(true, "Do you want download this file?", "");
    this.setStateByProps("action", "download");
  }

  render() {
    const {
      total_recipients,
      total_recipients_analyze,
      progressBar,
      columnsScheduling,
      dataScheduling,
      variable,
      screenwidth,
      variablePreview,
      loading,
      warningModalVisible,
      message,
      messageSuccess,
      successModalVisible,
      variableLog,
      search,
      data,
      columnsFile,
      dataFile,
      inputModalVisible,
      templateName,
      popUpTitel,
      createReportModalVisible,
      analyze_visible,
      analyzeTable,
      btnsend_loading,
      SUM_RECIPTENTS,
      sum_credit,
      total_credit,
      loadingAll,
      errorModalVisible,
      errorWarningModalVisible,
      messageError,
      modalVisible
    } = this.state;

    const routes = [
      {
        path: "communication",
        breadcrumbName: "Communication",
      },
      {
        breadcrumbName: data.campaignName,
      },
    ];

    var optionnumber = {
      justification: "L",
      locales: "en-US",
      percentage: false,
      precision: 0,
      wholenumber: null,
      commafy: true,
      shortFormat: true,
      shortFormatMinValue: 1000,
      shortFormatPrecision: 1,
      title: true,
    };

    return (
      <Spin spinning={loadingAll}>
        <ModalServerError modalVisible={modalVisible} />
        <div>
          <PageHeader
            className="pageHeaderCustom"
            title={
              <div className="headerTitle">
                <div>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <a href="/communication">Communication</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item style={{ fontSize: 29, fontWeight: 100 }}>
                      {data.campaignName}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <Button
                  style={{ margin: "0px", padding: "0px" }}
                  type="link"
                  onClick={() => this.onClickPreview(true)}
                >
                  Message Preview
                </Button>
              </div>
            }
          ></PageHeader>
          <div className="content">
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card>
                      <Row gutter={8} type="flex" justify="space-between">
                        <Col xs={24} sm={12} md={6}>
                          <Row>
                            <Col
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              style={{ textAlign: "right" }}
                            >
                              <img
                                src={require("../../../img/vendors/icon/valid_recipients_icon.png")}
                                style={{ height: 50, marginTop: 4 }}
                              />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={15} sm={15} md={15} lg={15} xl={15}>
                              <label className="ants-gray-low">
                                Valid Recipients
                              </label>
                              <div style={{ marginTop: -20 }}>
                                <span style={{ marginRight: 8, fontSize: 33 }}>
                                  {" "}
                                  {formatNumber(data.validRecipients)}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={24} sm={12} md={6}>
                          <Row>
                            <Col
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              style={{ textAlign: "right" }}
                            >
                              <img
                                src={require("../../../img/vendors/icon/total_messages_sent_icon.png")}
                                style={{ height: 40, marginTop: 8 }}
                              />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={15} sm={15} md={15} lg={15} xl={15}>
                              <label className="ants-gray-low">
                                Total Messages Sent
                              </label>
                              <div style={{ marginTop: -20 }}>
                                <span style={{ marginRight: 8, fontSize: 33 }}>
                                  {" "}
                                  {formatNumber(data.totalMessagesSent)}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={24} sm={12} md={6}>
                          <Row>
                            <Col
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              style={{ textAlign: "right" }}
                            >
                              <img
                                src={require("../../../img/vendors/icon/cost_icon.png")}
                                style={{ height: 50, marginTop: 4 }}
                              />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={15} sm={15} md={15} lg={15} xl={15}>
                              <label className="ants-gray-low">Cost</label>
                              <div style={{ marginTop: -20 }}>
                                <span style={{ marginRight: 8, fontSize: 33 }}>
                                  {" "}
                                  {formatNumber(data.costCredits)}
                                </span>
                                <span>CREDITS</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={24} sm={12} md={6}>
                          <Row>
                            <Col
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              style={{ textAlign: "right" }}
                            >
                              <img
                                src={require("../../../img/vendors/icon/creditRefund/Creditx1.png")}
                                style={{ height: 50, marginTop: 4 }}
                              />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={15} sm={15} md={15} lg={15} xl={15}>
                              <label className="ants-gray-low">Total Return Credits</label>
                              <div style={{ marginTop: -20 }}>
                                <span style={{ marginRight: 8, fontSize: 33 }}>
                                  {" "}
                                  {formatNumber(data.ANI_CREDIT - data.costCredits)}
                                </span>
                                <span>CREDITS</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            marginRight: 8,
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          Communication Summary
                        </div>
                        <div>
                          <Tag
                            style={{ textAlign: "center" }}
                            className={
                              data.campStatus
                                ? this.statusCampaignColor(data.campStatus)
                                : this.statusCampaignColor(1)
                            }
                          >
                            <span style={{ fontSize: 24, fontWeight: "bold" }}>
                              {data.campStatus
                                ? this.statusCampaignByid(data.campStatus)
                                : this.statusCampaignByid(1)}
                            </span>
                          </Tag>
                        </div>
                      </div>
                      <hr style={{ marginTop: 0 }} />

                      <Row gutter={16} type="flex" justify="end">
                        <Col
                          xs={24}
                          lg={8}
                          style={{ borderRight: "1px solid #e4e4e4" }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Progress
                              type="circle"
                              width={230}
                              percent={data.deliveryRate}
                              format={(percent) => (
                                <div>
                                  <span style={{ fontSize: 60 }}>
                                    {percent.toFixed(2)}%
                                  </span>
                                  <br />
                                  <span
                                    style={{ fontSize: 35, fontWeight: "bold" }}
                                  >
                                    Delivery Rate
                                  </span>
                                </div>
                              )}
                            />
                          </div>
                        </Col>

                        <Col xs={24} lg={16}>
                          <div style={{ marginLeft: 24 }}>
                            <span style={{ fontWeight: "bold" }}>
                              Delivery By Status
                            </span>
                            <br />
                            <Row gutter={8}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row gutter={8}>
                                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <span>Delivered</span>
                                  </Col>
                                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <Row gutter={8}>
                                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <NumericLabel params={optionnumber}>
                                          {data.delivered}
                                        </NumericLabel>
                                      </Col>
                                      <Col
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                      >
                                        <Progress
                                          percent={data.deliveryRate}
                                          format={(percent) =>
                                            percent.toFixed(2) + "%"
                                          }
                                          strokeColor="#1bbc9b"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row gutter={8}>
                                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <span>Undelivered</span>
                                  </Col>
                                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <Row gutter={8}>
                                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <NumericLabel params={optionnumber}>
                                          {data.undelivered}
                                        </NumericLabel>
                                      </Col>
                                      <Col
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                      >
                                        <Progress
                                          percent={data.undeliveredRate}
                                          format={(percent) =>
                                            percent.toFixed(2) + "%"
                                          }
                                          strokeColor="#95a5a5"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row gutter={8}>
                                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <span>Expired</span>
                                  </Col>
                                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <Row gutter={8}>
                                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <NumericLabel params={optionnumber}>
                                          {data.expired}
                                        </NumericLabel>
                                      </Col>
                                      <Col
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                      >
                                        <Progress
                                          percent={data.expiredRate}
                                          format={(percent) =>
                                            percent.toFixed(2) + "%"
                                          }
                                          strokeColor="#9a57b4"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row gutter={8}>
                                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <span>Rejected</span>
                                  </Col>
                                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <Row gutter={8}>
                                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <NumericLabel params={optionnumber}>
                                          {data.rejected}
                                        </NumericLabel>
                                      </Col>
                                      <Col
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                      >
                                        <Progress
                                          percent={data.rejectedRate}
                                          format={(percent) =>
                                            percent.toFixed(2) + "%"
                                          }
                                          strokeColor="#e44b3b"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row gutter={8}>
                                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <span>Completed</span>
                                  </Col>
                                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <Row gutter={8}>
                                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <NumericLabel params={optionnumber}>
                                          {data.completed}
                                        </NumericLabel>
                                      </Col>
                                      <Col
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                      >
                                        <Progress
                                          percent={data.completeRate}
                                          format={(percent) =>
                                            percent.toFixed(2) + "%"
                                          }
                                          strokeColor="#1bbc9b"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row gutter={8}>
                                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <span>Pending</span>
                                  </Col>
                                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <Row gutter={8}>
                                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <NumericLabel params={optionnumber}>
                                          {data.pending}
                                        </NumericLabel>
                                      </Col>
                                      <Col
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                      >
                                        <Progress
                                          percent={data.pendingRate}
                                          format={(percent) =>
                                            percent.toFixed(2) + "%"
                                          }
                                          strokeColor="#edc510"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div style={{ marginTop: "16px", textAlign: "center" }}>
                              <Tooltip
                                placement="top"
                                title={
                                  <div className="ant-tooltip-inner-red">
                                    {textforceStop}
                                  </div>
                                }
                              >
                                <Button
                                  type="primary-red"
                                  style={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    marginRight: 16,
                                  }}
                                  disabled={
                                    data.campStatus == 8 || data.campStatus == 3
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    this.warningModal(
                                      true,
                                      "Do you want to Force Stop this campaign? ",
                                      "Force Stop campaign successfully."
                                    ),
                                      this.setStateByProps("action", "cancel");
                                  }}
                                >
                                  <span className="fa fa-dot-circle-o"></span>{" "}
                                  &nbsp;&nbsp; FORCE STOP
                                </Button>
                              </Tooltip>

                              <Tooltip
                                placement="top"
                                title={
                                  <div>
                                    Only specified status message will be resent
                                    according to platform manual (
                                    <a
                                      target="_blank"
                                      href="https://servicedoc.ants.co.th/undefined#resend-undelivered"
                                    >
                                      Click
                                    </a>
                                    )
                                  </div>
                                }
                              >
                                <Button
                                  type="blue"
                                  key="submit"
                                  style={{ fontWeight: "bold" }}
                                  onClick={this.analyze_showDrawer}
                                  disabled={
                                    data.campStatus == 9 ||
                                      data.campStatus == 4 ||
                                      data.campStatus == 8 ||
                                      (new Date().getTime() -
                                        new Date(data.sentAt).getTime()) /
                                      (1000 * 60 * 60 * 24) >
                                      7 ||
                                      (data.undelivered == 0 && data.expired == 0)
                                      ? true
                                      : false
                                  }
                                >
                                  <span className="fa fa-repeat" style={{ marginRight: "10px" }}></span>
                                  RESEND UNDELIVERED
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card>
                      <Row gutter={{ xs: [16], md: [32] }} type="flex" justify="space-around">
                        <Col xs={12} md={6} >
                          <Button
                            key="submit"
                            style={{ width: "100%", fontWeight: "bold" }}
                            disabled={data.sentAt ? false : true}
                            onClick={() => {
                              this.createReportModal(
                                true,
                                "Would you to create a report for " +
                                data.campaignName +
                                "?",
                                "When successful, the report will be displayed in the report menu."
                              );
                              this.setState({ popUpTitel: "Get report" });
                              this.setStateByProps("action", "createreport");
                            }}
                          >
                            <Icon type="line-chart" />
                            GET REPORT
                          </Button>
                        </Col>
                        <Col xs={12} md={6} >
                          <Button
                            key="submit"
                            style={{ width: "100%", fontWeight: "bold" }}
                            disabled={
                              data.sentAt
                                ? data.campStatus == 4
                                  ? true
                                  : false
                                : true
                            }
                            onClick={() => this.onClickSeeLog()}
                          >
                            <Icon type="info-circle" />
                            SEE IN LOGS
                          </Button>
                        </Col>
                        <Col xs={12} md={6} >
                          <Button
                            key="submit"
                            style={{ width: "100%", fontWeight: "bold" }}
                            onClick={() => {
                              this.warningModal(
                                true,
                                "Do you want to duplicate this campaign?",
                                "Duplicated campaign successfully."
                              ),
                                this.setStateByProps("action", "copy");
                            }}
                          >
                            <span className="fa fa-files-o"></span> &nbsp;&nbsp;
                            DUPLICATE
                          </Button>
                        </Col>
                        <Col xs={12} md={6} >
                          <Button
                            key="submit"
                            style={{ width: "100%", fontWeight: "bold" }}
                            onClick={() => {
                              this.inputModal(
                                true,
                                "To save your new Template, first give it a name.",
                                "Save Template successfully."
                              ),
                                this.setStateByProps(
                                  "action",
                                  "createtemplate"
                                );
                            }}
                          >
                            <span
                              className="antsicon-see-log"
                              style={{ fontSize: 14 }}
                            ></span>
                            &nbsp;&nbsp;SAVE AS TEMPLATE
                          </Button>
                        </Col>
                      </Row>

                      <Row gutter={16} type="flex" justify="space-around">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Table
                            columns={columnsFile}
                            dataSource={dataFile}
                            pagination={false}
                            rowClassName="hoverrow"
                            onRow={(record, rowIndex) => {
                              return {
                                onClick: (item) => {
                                  record.fileoid
                                    ? this.confirmDownloadFile(
                                      record.fileoid,
                                      record.name
                                    )
                                    : null;
                                },
                              };
                            }}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                <Row gutter={8}>
                  <Col xs={24} sm={12} lg={12} xl={24}>
                    <Card>
                      <Card2_Extra
                        img={require("../../../img/vendors/icon/Total-Recipients.png")}
                        title={data.totalRecipients}
                        detail={"Total Recipients"}
                        data={total_recipients}
                        Table={TableRecipients}
                      />
                    </Card>
                  </Col>

                  <Col xs={24} sm={12} lg={12} xl={24}>
                    <Card>
                      <span
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "#7b7b7b",
                        }}
                      >
                        {" "}
                        Scheduling Options
                      </span>
                      <Collapse
                        defaultActiveKey={["1"]}
                        style={{
                          marginTop: -24,
                          paddingLeft: -20,
                          paddingRight: -20,
                        }}
                        bordered={false}
                        expandIconPosition={"right"}
                        expandIcon={({ isActive }) => (
                          <Icon
                            type="down-circle"
                            style={{ color: "#7b7b7b" }}
                            rotate={isActive ? 180 : 0}
                          />
                        )}
                      >
                        <Panel key="1">
                          <Table
                            pagination={false}
                            size="middle"
                            columns={columnsScheduling}
                            dataSource={dataScheduling}
                            className="table-ants"
                            showHeader={false}
                          />
                        </Panel>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Modal
            visible={variablePreview}
            style={{ top: 60 }}
            footer={false}
            onCancel={() => this.onClickPreview(false)}
          >
            <div
              className="phone-preview-container "
              style={{ marginLeft: "200px" }}
            >
              <div className="phone-content">
                <div className="phone-icon">
                  <img
                    src={require("../../../img/logo-symbol.png")}
                    alt="icon"
                    style={{ width: 20 }}
                  />
                </div>
                <p className="sender">{data.senderName}</p>
                <p className="msg-time">
                  Text message
                  <br />
                  {data.scheduledDate
                    ? moment(
                      moment(
                        moment(data.scheduledDate).format("YYYY/MM/DD HH:mm")
                      ),
                      "DD/MM/YYYY HH:mm"
                    ).format("llll")
                    : moment().format("llll")}
                </p>
                <p className="msg-container disable-scrollbars">
                  <span className="msg-content">
                    <MentionsInput
                      value={data.smsContent ? data.smsContent.trim() : ""}
                      style={MentionStyle_preview}
                    >
                      <Mention
                        markup="{__id__}"
                        data={variable}
                        style={{ backgroundColor: "#cee4e5" }}
                      />
                    </MentionsInput>
                  </span>
                </p>
              </div>
            </div>
          </Modal>

          <ModalWarning
            loading={loading}
            warningModalVisible={warningModalVisible}
            warningModal={this.warningModal}
            successModal={this.successModal}
            modalcountDown={this.modalcountDown}
            message={message}
            messageSuccess={messageSuccess}
          />

          <ModalSuccess
            loading={loading}
            successModalVisible={successModalVisible}
            successModal={this.successModal}
            messageSuccess={messageSuccess}
          />

          <ModalError
            loading={loading}
            errorModalVisible={errorModalVisible}
            errorModal={this.errorModal}
            messageError={messageError}
          />

          <ModalErrorWarning
            loading={loading}
            errorWarningModalVisible={errorWarningModalVisible}
            errorWarningModal={this.errorWarningModal}
            messageError={messageError}
          />

          <ModalInput
            loading={loading}
            inputModalVisible={inputModalVisible}
            inputModal={this.inputModal}
            successModal={this.successModal}
            modalcountDown={this.modalcountDown}
            message={message}
            messageSuccess={messageSuccess}
            setStateByProps={this.setStateByProps}
            placeholder={"Template Name"}
            inputValue={"templateName"}
            value={templateName}
          />

          <ModalCreateReport
            loading={loading}
            inputModalVisible={createReportModalVisible}
            inputModal={this.createReportModal}
            successModal={this.successModal}
            modalcountDown={this.modalcountDown}
            createreport={this.createreport}
            message={message}
            messageSuccess={messageSuccess}
            titel={popUpTitel}
          />

          <Drawer
            placement="right"
            zIndex="1020"
            closable={false}
            onClose={this.analyze_onClose}
            visible={analyze_visible}
            width={380}
          >
            <div>
              <Spin
                spinning={
                  TableRecipients &&
                    TableCredit &&
                    analyzeTable &&
                    !btnsend_loading
                    ? false
                    : true
                }
              >
                <Card2_Extra
                  img={require("../../../img/vendors/icon/Total-Recipients.png")}
                  title={SUM_RECIPTENTS}
                  detail={"Total Recipients"}
                  data={total_recipients_analyze}
                  Table={TableRecipients}
                />

                <Card2_Extra
                  img={require("../../../img/vendors/icon/Total-Credit.png")}
                  title={sum_credit}
                  detail={"Total Credit"}
                  data={total_credit}
                  Table={TableCredit}
                />

                <TableAnalyze data={analyzeTable} />
              </Spin>
              <br />
            </div>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                padding: "10px 16px",
                textAlign: "right",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
              }}
            >
              <Button
                icon="close-circle"
                style={{
                  marginRight: 8,
                }}
                onClick={this.analyze_onClose}
              >
                Cancel
              </Button>
              {SUM_RECIPTENTS ? (
                <Button
                  icon="check-circle"
                  type="primary"
                  loading={
                    TableRecipients &&
                      TableCredit &&
                      analyzeTable &&
                      !btnsend_loading
                      ? false
                      : true
                  }
                  onClick={() => {
                    this.warningModal(
                      true,
                      "Do you want to send messages?",
                      "Send messages successfully."
                    ),
                      this.setState({ action: "resend" });
                  }}
                >
                  Confirm
                </Button>
              ) : null}
            </div>
          </Drawer>
        </div>
      </Spin>
    );
  }
}
