import React, { useState } from "react";
import {
  Card, Col, Row,
} from "reactstrap";
import ClockGray from "../../../../img/clock(gray).svg"
import LetterGray from "../../../../img/letter(gray).svg"
import WriteGray from "../../../../img/write(gray).svg"
import CardSlider from "./CardSlider";

export default function CardCustom({ obj, onClickToPage, formatCurrency, history }) {

  const [showModal, setShowModal] = useState(false);

  const showModalTrigger = () => {
    setShowModal(true);
  }

  return (
    <div>
      <CardSlider showModal={showModal} setShowModal={setShowModal} history={history} />
      <Card className="card-package-center">
        <h4 className="card-title">Custom</h4>
        <div style={{ textAlign: "center", margin: "15px" }}>
          <div style={{ fontSize: "25px" }}>
            <span className="plan-title" style={{ fontSize: "2rem" }}>
              Custom Your Package
            </span>
          </div>
          <hr className="hr-card" />
          <div style={{ fontSize: "30px" }}>
            <div style={{ fontSize: "40px", color: "#19164F" }}>
              Price
            </div>
            <span className="plan-sub-title">
              By credit amount
            </span>
          </div>
        </div>
        <div className="card-content">
          <Row>
            <Col xl="6" style={{ borderRight: '1px solid #19164F' }}>
              <div className="text-desc">
                <img className="icon-card" src={ClockGray} />
                <span style={{ fontSize: "20px" }}>
                  Credit period
                </span>
              </div>
              <div className="text-desc">
                <img className="icon-card" src={ClockGray} />
                <span style={{ fontSize: "20px" }}>
                  Report period
                </span>
              </div>
              <div className="text-desc">
                <img className="icon-card" src={LetterGray} />
                <span style={{ fontSize: "20px" }}>
                  SMS Qty
                </span>
              </div>
              <div className="text-desc">
                <img className="icon-card" src={WriteGray} />
                <span style={{ fontSize: "20px" }}>
                  Sender name
                </span>
              </div>
            </Col>
            <Col xl="6">
              <div className="text-desc">
                <span style={{ fontSize: "20px" }}>
                  12 Month
                </span>
              </div>
              <div className="text-desc">
                <span style={{ fontSize: "20px" }}>
                  6 Month
                </span>
              </div>
              <div className="text-desc">
                <span style={{ fontSize: "20px" }}>
                  10,000
                </span>
              </div>
              <div className="text-desc">
                <span style={{ fontSize: "20px" }}>
                  10
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ margin: "0px 20px 28px" }}>
          <Card
            className={"button-black"}
            onClick={showModalTrigger}
          >
            <h5 style={{ color: "#fff", margin: "0px" }}>
              Custom
            </h5>
          </Card>
        </div>
      </Card>
    </div>
  );
}
