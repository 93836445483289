import React, { useState, useEffect } from "react";
import { Button, Icon, Input as InputAntd, Row, Col } from 'antd';

export default function ChangePassword({ state, btnLoginloading, handleResendEmail }) {

    useEffect(() => {

    }, []);

    return (
        <div className='login-first-card'>
            <h1 className="login-titel2">Please Change Your Password After First Login</h1>
            <hr />
            <p className="login2-body">
                &nbsp; &nbsp; &nbsp; Dear User,
                For the security of your account, please change your password immediately after logging in for the first time. An email with instructions on how to change your password has been sent to {state.email}.
                Thankyou for your cooperation.
                Best regards,
                The Team
            </p>
            <p>
                Thank you for your cooperation.
            </p>
            <Row>
                <Col xs={6}>
                    <Button className='login2-botton' type="success" style={{ float: 'left' }} loading={btnLoginloading} onClick={handleResendEmail}>
                        Resend email
                    </Button>
                </Col>
            </Row>
            <p className='login3-body'>
                Please check the correctness of your email and wait, the system is automatically sending an email to you.
                If it takes more than 5 minutes, please press the Resend button to send an email again.
            </p>
        </div>
    );
}
