import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { token } from '../../config';
import { apiClient } from '../../api';
import { Link } from 'react-router-dom';
import NumericLabel from 'react-pretty-numbers';
import {
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
} from 'reactstrap';
import HeaderDropdown from './HeaderDropdown';
import { Spin, Button } from 'antd';

const optionnumber = {
    justification: 'L',
    locales: 'en-US',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    cssClass: ['red'],
    shortFormat: true,
    shortFormatMinValue: 10000000,
    shortFormatPrecision: 1,
    title: true
};

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            intervalID: 0,
            settingJson: {
                env: {
                    title: "Loading...",
                    name: "ants",
                    imgFolder: "ANTS",
                },
                imgpath: null,
                classLogo: null
            },
        }
        this.getSettingJson = this.getSettingJson.bind(this);
    }

    componentDidMount() {

        let intervalID = setInterval(() => { this.props.onLoadCredit({ result: true }); }, 300000);
        this.setState({ intervalID: intervalID });
        this.getSettingJson();
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalID);
    }

    getSettingJson() {
        apiClient.get('api/function/getappsettings')
            .then((response) => {
                const json = response.data;
                console.log(json);

                this.setState({ settingJson: { ...this.state.settingJson, env: json.env } })
                switch (json.env.name) {
                    case "ANTS":
                        this.setState({ settingJson: { ...this.state.settingJson, classLogo: "navbar-brand-ants" } })
                        break;
                    case "YING":
                        this.setState({ settingJson: { ...this.state.settingJson, classLogo: "navbar-brand-ants3" } })
                        break;
                    case "SMS Up":
                        this.setState({ settingJson: { ...this.state.settingJson, classLogo: "navbar-brand-ants4" } })
                        break;
                    case "Promotech":
                        this.setState({ settingJson: { ...this.state.settingJson, classLogo: "navbar-brand-promotech" } })
                        break;
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    sidebarMinimize(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    //asideToggle(e) {
    //    e.preventDefault();
    //    document.body.classList.toggle('aside-menu-hidden');
    //}

    render() {
        const text = <span>Title</span>;
        const stylePopover = { width: 250 };
        const { username, admin, createuser, createaccount, balance, type, getCreditbalance, loading, userGenToken, ecom } = this.props;
        return (
            <header className="app-header navbar" >
                <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler>
                <NavbarBrand className={this.state.settingJson.classLogo} href="/console/home"></NavbarBrand>
                {/* //  Logo  บน Dash board   */}
                <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler>
                <Nav className="ml-auto" navbar >
                    <Link to={'/PackageList'} >
                        {ecom ? <Button
                            style={{ backgroundColor: "#EA7326", color: "#fff", marginRight: 15 }}
                        >
                            Buy package
                        </Button> : null}
                    </Link>


                    <div className="m-0" style={{ paddingRight: 15, cursor: 'pointer' }} >
                        <div style={{ marginTop: -5, marginBottom: -10 }}>{username}</div>
                        <small className="ants-gray-low">
                            Credit Balance: {
                                type == 0 && !loading ?
                                    <Spin size="small" />
                                    : loading ?
                                        <Spin size="small" />
                                        : balance == 0 ? "-" :
                                            <NumericLabel params={optionnumber}>
                                                {parseInt(balance)}
                                            </NumericLabel>
                            }
                        </small>
                    </div>

                    <HeaderDropdown accnt {...this.props} checkCreditBalance={getCreditbalance} ecom={ecom} />

                </Nav>
                {
                    //<Nav className="ml-auto" navbar onClick={this.asideToggle}>
                    //    <HeaderDropdown accnt />
                    //</Nav>
                }
            </header>
        );
    }
}

export default Header;
